export interface IDispatcherPanelAccessPoint {
  deviceId: string;
  deviceName: string;
}

export interface IDispatcherPanelObject {
  activeDeviceCount: number;
  lostDeviceCount: number;
  objectId: string;
  objectName: string;
  withErrorsDeviceCount: number;
  withWarningsDeviceCount: number;
  withoutEventsDeviceCount: number;
  latitude: number;
  longitude: number;
  activeDeviceName: IDispatcherPanelAccessPoint[];
  lostDeviceName: IDispatcherPanelAccessPoint[];
  withErrorsDeviceName: IDispatcherPanelAccessPoint[];
  withWarningsDeviceName: IDispatcherPanelAccessPoint[];
  withoutEventsDeviceName: IDispatcherPanelAccessPoint[];
  isInEmergency: boolean;
}

export interface IDispatcherPanelDeviceShort {
  id: string;
  name: string;
}

export enum EDispatcherDeviceStatus {
  none = 'None',
  active = 'Active',
  withWarning = 'WithWarning',
  withErrors = 'WithErrors',
  withoutEvents = 'WithoutEvents',
  lost = 'Lost',
  all = 'All',
}

export interface IDisablePushPeriod {
  objectName: string;
  deviceIds: IDispatcherPanelDeviceShort[];
  deviceStatuses: EDispatcherDeviceStatus[];
  endDate: string;
  id: string;
  minutes: number;
  objectId: string;
  type: string;
  userId: '4651751a-ddac-4dc2-a95a-95364f866a15';
}

export interface IDispatcherPanelDevice {
  address: string;
  deviceId: string;
  header: string;
  deviceName: string;
  deviceType: string;
  errors: string[];
  hasNotification: boolean;
  notificationIsExpired: boolean;
  objectName: string;
  status: EDispatcherDeviceStatus;
  warnings: string[];
}

export interface IDispatcherPanelDeviceInfo {
  address: string;
  communicationMethod: string;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  errors: string[];
  isLastVersion: boolean;
  lastStatusTime: string;
  objectName: string;
  objectId: string;
  serialNumber: string;
  status: string;
  warnings: string[];
}

export interface IDispatcherPanelJournal {
  date: string;
  errors: string[];
  id: string;
  isLastConfigurationVersion: boolean;
  isLastKeyVersion: boolean;
  isLastUserVersion: boolean;
  status: EDispatcherDeviceStatus;
  warnings: string[];
}

export interface IDispatcherPanelNotification {
  createDate: string;
  date: string;
  deviceId: string;
  id: string;
  resolveDate: string;
  text: string;
  withNotification: boolean;
}

export interface IEmergencySettings {
  duration: number;
}

export interface IEmergencyParams extends IEmergencySettings {
  reasonId?: string;
  customNotificationText?: string;
}

export enum IDispatcherPanelColors {
  green = '#48c80b',
  yellow = '#ffe600',
  red = '#f00200',
  blue = '#1344f2',
  gray = '#949494',
}

export const fromStatusToColor = new Map([
  [EDispatcherDeviceStatus.active, 'green'],
  [EDispatcherDeviceStatus.withWarning, 'yellow'],
  [EDispatcherDeviceStatus.withErrors, 'red'],
  [EDispatcherDeviceStatus.lost, 'gray'],
  [EDispatcherDeviceStatus.withoutEvents, 'blue'],
  [null, 'blue'],
]);

export const fromStatusToName = new Map([
  [EDispatcherDeviceStatus.active, 'Работает исправно'],
  [EDispatcherDeviceStatus.withWarning, 'Работает с некритичными проблемами'],
  [EDispatcherDeviceStatus.withErrors, 'Аварийное состояние'],
  [EDispatcherDeviceStatus.lost, 'Нет связи'],
  [EDispatcherDeviceStatus.withoutEvents, 'Ввод в эксплуатацию'],
  [null, 'Ввод в эксплуатацию'],
]);
