import React, { FC, useCallback, useState } from 'react';
import { YMapMarker } from '../../yandexMap';
import { IMapItemProps, ObjectMapItemType } from '../types';
import InfoTip from './infoTip';
import DoorCircleActiveIcon from '../../../assets/svg/icons/doorCircleActive';
import DoorCircleIcon from '../../../assets/svg/icons/doorCircle';
import CameraCircleIcon from '../../../assets/svg/icons/cameraCircle';
import CameraCircleActiveIcon from '../../../assets/svg/icons/cameraCircleActive';
import { AccessPointType } from '../../../typings/treeNode';
import WicketCircleIcon from '../../../assets/svg/icons/wicketCircle';
import WicketCircleActiveIcon from '../../../assets/svg/icons/wicketCircleActive';

const MapItem: FC<IMapItemProps> = (props) => {
  const { item, isSelected, onClick, onMoved } = props;

  const [isInfoTipVisible, setInfoTipVisible] = useState(false);

  const onMouseEnter = useCallback(() => {
    setInfoTipVisible(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (isInfoTipVisible) {
      setInfoTipVisible(false);
    }
  }, [isInfoTipVisible]);

  const handleClick = useCallback(() => {
    setInfoTipVisible(false);
    onClick(item);
  }, [item, onClick]);

  const renderIcon = useCallback(() => {
    if (item.type === ObjectMapItemType.accessPoint) {
      if (item.deviceType === AccessPointType.wicket) {
        return !isSelected ? <WicketCircleIcon /> : <WicketCircleActiveIcon />;
      }
      return !isSelected ? <DoorCircleIcon /> : <DoorCircleActiveIcon />;
    }
    return !isSelected ? <CameraCircleIcon /> : <CameraCircleActiveIcon />;
  }, [isSelected, item.deviceType, item.type]);

  const renderItem = useCallback(
    () => (
      <div
        role="presentation"
        className="schema-map__item"
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {renderIcon()}
        {isInfoTipVisible && <InfoTip item={item} />}
      </div>
    ),
    [handleClick, isInfoTipVisible, item, onMouseEnter, onMouseLeave, renderIcon]
  );

  return item?.latitude && item?.longitude ? (
    <YMapMarker
      key={item.id}
      coordinates={[item.longitude, item.latitude]}
      draggable
      onDragEnd={(coordinates) => onMoved(item, coordinates)}
    >
      {renderItem()}
    </YMapMarker>
  ) : null;
};

export default MapItem;
