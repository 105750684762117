import React, { FC } from 'react';

const EmergencyIcon: FC = () => (
  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVR4nM2VW4hNYRTHt8tMSYyz19ozLkOiplHkYUqT25iz1j4zzBN1KJdSagqPIxQa3iQUKRqUe1HDG4XyQHjggfGC5DYGxcxZa8+ZBy9b61wGU+fM2ad5sGrVV+v7vt9q/df6Psf5Xwz8YCeSHC7ZfV0ZCYAsbUjaXqJfBtbjkQC1jeEkYN0DrCddlsaiyZC2RwYg60VkvYBx3YYsvdWUmjfWgA/TW9WzNZB0AaU25WMx7p+DrEecZDihbACQXkOS08CpjcjyGUnqhoNN4UQk6UbWqwYZFQCcnumyrvP8IGmOLGtc1gVAchBYzwMFq7L7dDuSPjMHlhfIGiLrvqKAWGJodi7DbiC5kXHW28DyDVlu1fhBdX5vjf91smlhjqT7kfVjbl0YkL1QOjzW5bbR82VrvgwQ191A+saS+PuMG0/PAtZ3eeELAoADQpZXViIk+QIsD4Hk+nA8ofXWRUD6diQkL3BRQFZE6TCxkLUTWR+DH8RzsTpgGTI9cpA0kvYj612bkX/vKQDwfF0GpO+t1m48tQRYXztOOC5ziOUMsJwzfVxf11a1pWJTSMDE9ZplaUmAEVp0AcsuW1v/A8n3GPdXVXOwCFifZ7In/YkkN52GsCISoKZlYC6y/MK4rM7BrD2PFc0oCgBIzgLJIyR94iTDSnt/kPRo2YCqpoFpSHJquO9J+tzWH1PtzQHWE1YaJPnkJQYXlwXIDckVlwI295pT88GXzcjyAEl6kIP1SMEGJH3qdIbjowPsrWfpRZZ7GSe5b5Mb84OFmd63mUhoPbDeAdIdkQFmblO6FhOphrxb2f4csOylJwMk6cOWwRmRAaMZZIW2n2ovkl4ac4CTDCtNA7scWF7a0wAsW8bsyzSzgUPSA5luaggrgORQwU8/risiA8qx3+ppzby0BwxBAAAAAElFTkSuQmCC"
    alt=""
  />
);

export default EmergencyIcon;
