import CustomersIcon from '../assets/svg/icons/customers';
import DeviceEventIcon from '../assets/svg/icons/deviceEvent';
import FaqInfoIcon from '../assets/svg/icons/faqInfo';
import HomeIcon from '../assets/svg/icons/home';
import { paths } from './paths';
import { ESidebarItemIds, ISidebarItem } from '../typings/sidebar';
import UnionItemsIcon from '../assets/svg/icons/unionItems';
import OrganizationIcon from '../assets/svg/icons/organization';
import RolesIcon from '../assets/svg/icons/roles';
import UsersIcon from '../assets/svg/icons/users';
import ApartmentsIcon from '../assets/svg/icons/apartmentsIcon';
import DispatcherPanelIcon from '../assets/svg/icons/dispatcherPanel';
import FeedbackIcon from '../assets/svg/icons/feedback';
import GuideIcon from '../assets/svg/icons/guide';
import MailingIcon from '../assets/svg/icons/mailing';
import DispatcherViewCenterIcon from '../assets/svg/icons/dispatcherViewCenter';

export const sidebar: ISidebarItem[] = [
  {
    id: ESidebarItemIds.objects,
    link: paths.systems,
    logo: HomeIcon,
    title: 'Объекты',
  },
  {
    id: ESidebarItemIds.flatInfo,
    link: paths.flats,
    logo: ApartmentsIcon,
    title: 'Квартиры',
  },
  {
    id: ESidebarItemIds.subscribers,
    link: paths.subscribers,
    logo: CustomersIcon,
    title: 'Абоненты',
  },
  {
    id: ESidebarItemIds.logs,
    link: paths.deviceEvent,
    logo: DeviceEventIcon,
    title: 'Логирование событий оборудования',
  },
  {
    id: ESidebarItemIds.users,
    link: paths.users,
    logo: UsersIcon,
    title: 'Пользователи',
  },
  {
    id: ESidebarItemIds.roles,
    link: paths.roles,
    logo: RolesIcon,
    title: 'Роли',
  },
  {
    id: ESidebarItemIds.mpManagement,
    logo: UnionItemsIcon,
    title: 'Управление МП',
    isOpen: false,
    categoryItems: [
      {
        id: ESidebarItemIds.tariffs,
        link: paths.mpManagementTariffs,
        title: 'Тарифы',
      },
      {
        id: ESidebarItemIds.tariffCost,
        link: paths.mpManagementTariffsCost,
        title: 'Стоимость тарифов',
      },
      {
        id: ESidebarItemIds.payments,
        link: paths.mpManagementSubscriptions,
        title: 'Подписки',
      },
      {
        id: ESidebarItemIds.payments,
        link: paths.mpManagementPayments,
        title: 'Платежи',
      },
      {
        id: ESidebarItemIds.subscriberFAQ,
        link: paths.mpManagementFAQ,
        title: 'Конструктор FAQ абонентов',
      },
    ],
  },
  {
    id: ESidebarItemIds.help,
    logo: FaqInfoIcon,
    title: 'Справка',
    isOpen: false,
    categoryItems: [
      {
        id: ESidebarItemIds.helpFaq,
        link: paths.helpFaq,
        title: 'FAQ частые вопросы',
      },
      {
        id: ESidebarItemIds.helpInstructions,
        link: paths.helpInstructions,
        title: 'Инструкции',
      },
    ],
  },
  {
    id: ESidebarItemIds.guide,
    logo: GuideIcon,
    title: 'Справочник',
    isOpen: false,
    categoryItems: [
      {
        id: ESidebarItemIds.feedbackCategory,
        link: paths.guideCategory,
        title: 'Категории обращений',
      },
      {
        id: ESidebarItemIds.emergencyReason,
        link: paths.guideEmergencyReasons,
        title: 'Причины ЧС',
      },
    ],
  },
  {
    id: ESidebarItemIds.organization,
    logo: OrganizationIcon,
    title: 'Организация',
    isOpen: false,
    categoryItems: [
      {
        id: ESidebarItemIds.organizationInfo,
        link: paths.organizationInfo,
        title: 'Информация',
      },
    ],
  },
  {
    id: ESidebarItemIds.feedback,
    link: paths.feedback,
    logo: FeedbackIcon,
    title: 'Обращения',
  },
  {
    id: ESidebarItemIds.mailing,
    link: paths.mailing,
    logo: MailingIcon,
    title: 'Рассылка',
  },
  {
    id: ESidebarItemIds.dispatcherPanel,
    link: paths.dispatcherPanel,
    logo: DispatcherPanelIcon,
    countField: ESidebarItemIds.dispatcherPanel,
    title: 'Ситуационная панель',
    countDescription: 'Оборудование в аварийном состоянии',
  },
  {
    id: ESidebarItemIds.dispatcherViewCenter,
    link: paths.dispatcherViewCenter,
    logo: DispatcherViewCenterIcon,
    title: 'Панель диспетчера',
  },
];
