import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CameraIconType, ICameraPoint, ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { IInputValue, InputStatus, defaultNotRequiredValue } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import Tooltip from '../../../ui/tooltip';
import { TreeNodeNamesMap } from '../constans';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm } from '../../../ui/universalModal/config';
import UniversalModal from '../../../ui/universalModal';
import { ICameraIcon, ICameraModal } from './types';
import CameraIcon from '../../../../assets/svg/icons/camera';
import { cameraIconsMap } from '../templates';
import CoordinateInput from '../../../ui/input/maskInput/coordinateInput';

const CameraModal: FC<ICameraModal> = (props) => {
  const {
    isOpen = false,
    schema = null,
    onCreate = () => {},
    onCancel = () => {},
    editObject,
    onEdit = () => {},
    getLayout = () => '',
    parent,
  } = props;

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [name, setName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [shortName, setShortName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [nameDispatcherPanel, setNameDispatcherPanel] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [coordinates, setCoordinates] = useState<IInputValue>({ ...defaultNotRequiredValue });

  const [activeIcon, setActiveIcon] = useState<ICameraIcon | null>({
    type: CameraIconType.default,
    icon: <CameraIcon key="camera" />,
  });

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) =>
      setter({ ...defaultNotRequiredValue, value }),
    []
  );

  const findAllCameras = useCallback(
    (node: ITreeNode, camerasList: ICameraPoint[]) => {
      if (node.cameras) {
        node.cameras.forEach((item) => {
          if (editObject && editObject.id === item.id) {
            return null;
          }
          camerasList.push(item);
        });
      }
      if (node.childItems) {
        node.childItems?.forEach((item) => findAllCameras(item, camerasList));
      }
    },
    [editObject]
  );

  const allCamerasNames = useMemo((): string[] => {
    const res: ICameraPoint[] = [];

    if (schema) {
      schema.forEach((item) => findAllCameras(item, res));
    }
    return res.map((item) => item.name);
  }, [findAllCameras, schema]);

  const validateInputs = useCallback(() => {
    let isValid = true;

    if (!name.value || !name.value.trim()) {
      setName({ ...name, status: InputStatus.error, errorText: 'Поле обязательно для заполнения' });
      isValid = false;
    }

    if (allCamerasNames.includes(name.value)) {
      setName({ ...name, status: InputStatus.error, errorText: 'Камера с таким названием уже существует' });
      isValid = false;
    }

    if (!shortName.value || !shortName.value.trim()) {
      setShortName({ ...shortName, status: InputStatus.error, errorText: 'Поле обязательно для заполнения' });
      isValid = false;
    }

    return isValid;
  }, [allCamerasNames, name, shortName]);

  const handleOnClickSubmitButton = useCallback(() => {
    if (validateInputs()) {
      const isNew = !!(editObject ? editObject.isNew : true);
      const newCamera: ICameraPoint = {
        id: editObject?.id || uuidv4(),
        isNew,
        type: TreeNodeType.camera,
        name: name.value,
        shortName: shortName.value,
        nameDispatcherPanel: nameDispatcherPanel.value,
        coordinates: coordinates.value,
      };
      newCamera.layout = getLayout(newCamera, editObject?.layout || null, true);
      if (editObject) {
        onEdit(newCamera);
      } else {
        onCreate(newCamera);
      }
      onCancel();
    }
  }, [
    editObject,
    getLayout,
    name.value,
    onCancel,
    onCreate,
    onEdit,
    shortName.value,
    nameDispatcherPanel.value,
    coordinates.value,
    validateInputs,
  ]);

  useEffect(() => {
    if (!editObject) {
      setName({ ...defaultNotRequiredValue });
      setShortName({ ...defaultNotRequiredValue });
      setNameDispatcherPanel({ ...defaultNotRequiredValue });
      setCoordinates({ ...defaultNotRequiredValue });
    }
  }, [editObject, isOpen]);

  useEffect(() => {
    if (editObject) {
      setName({ ...defaultNotRequiredValue, value: editObject.name || '' });
      setShortName({ ...defaultNotRequiredValue, value: editObject.shortName || '' });
      setNameDispatcherPanel({ ...defaultNotRequiredValue, value: editObject.nameDispatcherPanel || '' });
      setCoordinates({ ...defaultNotRequiredValue, value: editObject.coordinates || '' });
    }
  }, [editObject]);

  const renderIcons = useCallback(() => {
    const result: JSX.Element[] = [];
    cameraIconsMap.forEach((value, key) =>
      result.push(
        <Tooltip key={key} title={value.description} placement="bottom">
          <div
            className={classNames(
              'access-point-modal__icons-item',
              activeIcon?.type === key ? 'access-point-modal__icons-item_active' : ''
            )}
          >
            {value.element}
          </div>
        </Tooltip>
      )
    );
    return result;
  }, [activeIcon?.type]);

  return (
    <Modal
      isOpen={isOpen}
      title={editObject ? 'Редактирование камеры' : 'Создание камеры'}
      onCancel={onCancel}
      wrapClassName="schema-modal__wrapper"
      width={488}
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={handleOnClickSubmitButton} size={ButtonSize.small}>
            {editObject ? 'Редактировать' : 'Создать'}
          </Button>
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="schema-modal default-scrollbar-override access-point-modal">
        {!editObject && parent?.type && TreeNodeNamesMap.get(parent.type) && (
          <div className="schema-modal__parent">
            {TreeNodeNamesMap.get(parent.type)}. {parent.object?.name}
          </div>
        )}
        <div className="access-point-modal__input-container">
          <Input
            title="Название камеры"
            maxLength={100}
            value={name.value}
            status={name.status}
            onChange={(value) => handleOnChangeInput(setName, value)}
            placeholder="Название камеры"
            textInfo="Это название будет использоваться в мобильном приложении"
            isRequired
            errorText={name.errorText}
          />
        </div>
        <div className="access-point-modal__camera-icon-container ">
          <Input
            title="Сокращенное название"
            isRequired
            maxLength={5}
            textInfo="Для отображения на плане"
            value={shortName.value}
            status={shortName.status}
            onChange={(value) => handleOnChangeInput(setShortName, value)}
            placeholder="Сокращенное название точки доступа"
            errorText={shortName.errorText}
          />
          <div className="access-point-modal__icons">
            <div className="access-point-modal__icons-title">Изображение на схеме</div>
            <div className="access-point-modal__icons-container">{renderIcons()}</div>
          </div>
        </div>
        <div className="access-point-modal__input-container">
          <Input
            title="Название для Панели диспетчера"
            maxLength={100}
            value={nameDispatcherPanel.value}
            status={nameDispatcherPanel.status}
            onChange={(value) => handleOnChangeInput(setNameDispatcherPanel, value)}
            placeholder="Название для Панели диспетчера"
            textInfo="Это название будет использоваться в Панели диспетчера, при выводе окна с видеопотоком камеры"
            errorText={nameDispatcherPanel.errorText}
          />
        </div>
        <div className="access-point-modal__input-container__row">
          <CoordinateInput
            title="Координаты WGS 84"
            placeholder="Координаты WGS 84"
            value={coordinates.value}
            onChange={(value) => handleOnChangeInput(setCoordinates, value)}
            status={coordinates.status}
            errorText={coordinates.errorText}
            textInfo="Для размещения на плане в режиме просмотра карты"
            titleClassName="input__title-container"
          />
        </div>
      </div>
    </Modal>
  );
};

export default CameraModal;
