import React, { FC, useMemo } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import { IEmergencyReasonTableProps } from './types';
import { IEmergencyReason } from '../../../../typings/guide';
import Scrollbar from '../../../ui/scrollbar';
import TrashIcon from '../../../../assets/svg/icons/trash';
import Tooltip from '../../../ui/tooltip';
import EditIcon from '../../../../assets/svg/icons/edit';

const EmergencyReasonTable: FC<IEmergencyReasonTableProps> = (props) => {
  const {
    permissions = {},
    texts = [],
    loading = false,
    currentPage = 0,
    pageSize = 10,
    total = 0,
    isSearch = false,
    onChangePage = () => {},
    onEditRow = () => {},
    onDeleteRow = () => {},
  } = props;

  const columns = useMemo<ColumnsType<IEmergencyReason>>(
    () =>
      [
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
          render: (_: any, record: IEmergencyReason) => (
            <div className="editable-table__data-container">{record.name}</div>
          ),
        },
        {
          className: 'emergency-reason-table__icon-cell',
          hidden: !(permissions.edit || permissions.delete),
          render: (_: any, record: IEmergencyReason) => (
            <div role="presentation" className="editable-table__icon-container">
              {permissions.edit && (
                <Tooltip title="Редактировать" placement="bottom">
                  <div
                    onClick={() => onEditRow(record.id || '', record)}
                    role="presentation"
                    className="emergency-reason-table__icon"
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
              )}
              {permissions.delete && (
                <Tooltip title="Удалить" placement="bottom">
                  <div
                    onClick={() => onDeleteRow(record.id || '', record)}
                    role="presentation"
                    className="emergency-reason-table__icon delete"
                  >
                    <TrashIcon />
                  </div>
                </Tooltip>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [permissions.edit, permissions.delete, onEditRow, onDeleteRow]
  );

  const data = useMemo(
    () =>
      texts.map((item, index) => ({
        ...item,
        key: index,
      })),
    [texts]
  );

  return (
    <div className="emergency-reason-table editable-table">
      <Scrollbar>
        <Table
          scroll={{ x: '100vh' }}
          columns={columns}
          dataSource={data}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default EmergencyReasonTable;
