import React, { FC } from 'react';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IEmergencyConfirmProps } from './types';

const EmergencyConfirmModal: FC<IEmergencyConfirmProps> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Вы действительно хотите перевести объект в режим ЧС? Все устройства на объекте будут разблокированы."
      width={416}
      showCloseIcon
      wrapClassName="confirm-emergency-modal__wrapper"
      centered
      footer={
        <div className="confirm-emergency-modal__button-container">
          <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={onCancel}>
            Нет
          </Button>
          <Button size={ButtonSize.small} onClick={onOk}>
            Да
          </Button>
        </div>
      }
    />
  );
};

export default EmergencyConfirmModal;
