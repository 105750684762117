import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { getSystem } from '../../api/systems';
import CommonHead from '../../components/commonHead';
import SystemsTable from '../../components/systemsTable';
import Button from '../../components/ui/button';
import { paths } from '../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { setHeaderTitle } from '../../store/slices/header';
import {
  checkObjectInEmergencyUrl,
  clearObjectEmergencyUrl,
  companyTariffVersionUrl,
  companyUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
  objectCountRemainingUrl,
  setObjectEmergencyUrl,
} from '../../constants/api';
import { IApiQueryParams, IApiResponse } from '../../typings/api';
import { ESidebarItemIds } from '../../typings/sidebar';
import { ISystem } from '../../typings/systems/system';
import { ESystemTabsIds } from './item/types';
import { getRequest, postRequest } from '../../api';
import { ISelectOption } from '../../components/ui/select/types';
import { EPageQueryParams } from '../../typings/searchParams';
import WarningBlock from '../../components/ui/warningBlock';
import { ICompanyTariff } from '../../typings/tariffs';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';
import UpArrow from '../../assets/svg/icons/upArrow';
import TransferObjectModal from '../../components/transferObjectModal';
import { IEmergencyParams } from '../../typings/dispatcherPanel';
import EmergencyModal from '../../components/dispatcherPanel/emergencyModal';
import EmergencyConfirmModal from '../../components/dispatcherPanel/emergencyConfirmModal';
import EmergencyClearConfirmModal from '../../components/dispatcherPanel/emergencyClearConfirmModal';

const Systems: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, sendRequest, loading } = useApi<IApiResponse<ISystem>>(getSystem);
  const [apiSettings, setApiSettings] = useState<IApiQueryParams<ISystem>>({
    page: 0,
    count: 10,
    search: '',
  });
  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.objects));
  const canObjectReceive = !!useAppSelector(getProfilePermission(ESidebarItemIds.objectReceive))?.create;

  const { data: companies, sendRequest: getCompanies } = useApi<ISystem[]>(getRequest);

  const isAdmin = useAppSelector(checkIsAdmin);

  const [companyId, setCompanyId] = useState<string | number>('');

  const { data: tariffInfo, sendRequest: getTariffInfo } = useApi<ICompanyTariff>(getRequest);
  const { data: objectCounter, sendRequest: getObjectCounter } = useApi<any>(getRequest);

  const [emergencySystem, setEmergencySystem] = useState<ISystem>();
  const [isEmergencyModalOpen, setEmergencyModalOpen] = useState(false);
  const [isEmergencyConfirmModalOpen, setEmergencyConfirmModalOpen] = useState(false);
  const [isEmergencyClearConfirmModalOpen, setEmergencyClearConfirmModalOpen] = useState(false);
  const [emergencyParams, setEmergencyParams] = useState<IEmergencyParams>();

  const { sendRequest: setObjectEmergency, loading: loadingSetObjectEmergency } = useApi(postRequest);
  const { sendRequest: clearObjectEmergency, loading: loadingClearObjectEmergency } = useApi(postRequest);
  const { sendRequest: checkObjectInEmergency } = useApi(getRequest);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if ((objectCounter || objectCounter === 0) && objectCounter <= 0) getTariffInfo(companyTariffVersionUrl());
  }, [objectCounter]);

  useEffect(() => {
    dispatch(setHeaderTitle('Объекты'));
  }, [dispatch]);

  useEffect(() => {
    if (isAdmin !== null)
      if (isAdmin) {
        getCompanies(companyUrl(), {
          params: {
            page: 0,
            count: 0,
            search: '',
          },
        });
      } else getObjectCounter(objectCountRemainingUrl());
  }, [isAdmin]);

  const getObjects = useCallback(
    async (params = apiSettings, id = companyId) => {
      if (id) {
        await sendRequest(getObjectSystemIdUrl(id.toString()), { params });
      } else {
        await sendRequest(getObjectSystemsListUrl(), { params });
      }
    },
    [apiSettings, companyId, sendRequest]
  );

  useEffect(() => {
    getObjects();
  }, []);

  const handleOnClickButtonNew = useCallback(() => navigate(paths.systemsNew), [navigate]);

  const handleOnClickRow = useCallback(
    (systemId: string) =>
      navigate({
        pathname: `${paths.systems}/${systemId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESystemTabsIds.basic })}`,
      }),
    [navigate]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: page - 1 };
      await getObjects(newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0, search: value };
      await getObjects(newApiSettings);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0, search: values.search };
      setCompanyId(values.companyId || '');
      getObjects(newApiSettings, values.companyId || '');
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnChangeCompanyId = useCallback(
    async (id: string | number) => {
      setCompanyId(id);
      const newApiSettings: IApiQueryParams<ISystem> = { ...apiSettings, page: 0 };
      getObjects(newApiSettings, id);
      setApiSettings(newApiSettings);
    },
    [apiSettings, getObjects]
  );

  const handleOnClickEmergency = (record: ISystem, event: SyntheticEvent) => {
    event.stopPropagation();
    if (loadingSetObjectEmergency || loadingClearObjectEmergency) return;
    setEmergencySystem(record);
    if (!record.isInEmergency) {
      setEmergencyModalOpen(true);
    } else {
      setEmergencyClearConfirmModalOpen(true);
    }
  };

  const onEmergencyModalOk = (value: IEmergencyParams) => {
    setEmergencyParams(value);
    setEmergencyModalOpen(false);
    setEmergencyConfirmModalOpen(true);
  };

  const onEmergencyModalCancel = () => {
    setEmergencyModalOpen(false);
  };

  const onEmergencyConfirmModalOk = () => {
    setEmergencyConfirmModalOpen(false);
    if (!emergencySystem) return;
    setObjectEmergency(setObjectEmergencyUrl(emergencySystem.id as string), emergencyParams).then(() => {
      checkObjectInEmergency(checkObjectInEmergencyUrl(emergencySystem.id as string)).then((res) => {
        if (res.success) {
          getObjects();
        }
      });
    });
  };

  const onEmergencyConfirmModalCancel = () => {
    setEmergencyConfirmModalOpen(false);
  };

  const onEmergencyClearConfirmModalOk = () => {
    if (!emergencySystem) return;
    setEmergencyClearConfirmModalOpen(false);
    clearObjectEmergency(clearObjectEmergencyUrl(emergencySystem.id as string), {}).then(() => {
      checkObjectInEmergency(checkObjectInEmergencyUrl(emergencySystem.id as string)).then((res) => {
        if (res.success) {
          getObjects();
        }
      });
    });
  };

  const onEmergencyClearConfirmModalCancel = () => {
    setEmergencyClearConfirmModalOpen(false);
  };

  const renderRangesMenu = useCallback(
    () => (
      <Menu
        className="subscribers__range-dropdown"
        items={[
          {
            label: 'Создать новый',
            key: 'add-subscriber',
            className: 'subscribers__range-dropdown-item',
            onClick: handleOnClickButtonNew,
          },
          {
            label: 'Добавить по коду',
            key: 'upload-excel',
            className: 'subscribers__range-dropdown-item',
            onClick: () => setModalOpen(true),
          },
        ]}
      />
    ),
    [handleOnClickButtonNew]
  );

  const organizationOptions = useMemo(
    () =>
      isAdmin && companies
        ? companies.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Объекты' }} />
      <TransferObjectModal isOpen={modalOpen} onOk={() => setModalOpen(false)} onCancel={() => setModalOpen(false)} />

      <div className="systems">
        {(objectCounter || objectCounter === 0) && tariffInfo && objectCounter <= 0 ? (
          <WarningBlock
            wrapperClassName="systems__warning"
            messages={[
              `Лимит на создание объектов по тарифу «${tariffInfo?.version}» исчерпан. Для изменения тарифа обратитесь к администратору Eltis.`,
            ]}
          />
        ) : null}

        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  containerClassName: isAdmin ? '' : 'filters-tool__item_mb',
                  placeholder: 'Название или адрес объекта, серийный или SIP номер оборудования, MAC-адрес камеры',
                  value: apiSettings?.search,
                  showClear: true,
                  onSearch: handleOnSearch,
                },
              },
              {
                hidden: !permissionObject?.create && !canObjectReceive,
                type: EFilterTool.extraContent,
                props: {
                  children: (
                    <div className="systems__cell">
                      {canObjectReceive &&
                      permissionObject?.create &&
                      (objectCounter === '' ? true : (objectCounter || 0) >= 0) ? (
                        <Dropdown
                          overlay={renderRangesMenu}
                          trigger={['click']}
                          disabled={objectCounter === '' ? false : (objectCounter || 0) <= 0}
                        >
                          <Button
                            className="systems__button"
                            rightIcon={
                              <span className="systems__button-icon">
                                <UpArrow />
                              </span>
                            }
                          >
                            Добавить объект
                          </Button>
                        </Dropdown>
                      ) : canObjectReceive ? (
                        <Button className="systems__button" onClick={() => setModalOpen(true)}>
                          Добавить объект по коду
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            objectCounter === '' ? false : (objectCounter || 0) <= 0 || permissionObject?.create
                          }
                          className="systems__button"
                          onClick={handleOnClickButtonNew}
                        >
                          Добавить объект
                        </Button>
                      )}

                      {(objectCounter || objectCounter === 0) && (
                        <div className="systems__count">Доступно объектов {objectCounter || 0}</div>
                      )}
                    </div>
                  ),
                },
              },
              {
                type: EFilterTool.select,
                id: 'companyId',
                hidden: !isAdmin,
                props: {
                  showClear: true,
                  isAllOption: true,
                  title: 'Организация',
                  value: companyId,
                  onChange: handleOnChangeCompanyId,
                  options: organizationOptions,
                  containerClassName: 'systems__select',
                },
              },
            ],
          ]}
        />
        <SystemsTable
          systems={data?.items?.reverse() || []}
          onClickRow={handleOnClickRow}
          onEditRow={handleOnClickRow}
          onClickEmergency={handleOnClickEmergency}
          permissions={permissionObject}
          loading={loading}
          pagination={{
            pageSize: data?.pageSize || 0,
            currentPage: data?.page || 0,
            total: data?.totalCount || 0,
            onChangePage: handleOnChangeTablePage,
          }}
        />
      </div>
      <EmergencyModal isOpen={isEmergencyModalOpen} onOk={onEmergencyModalOk} onCancel={onEmergencyModalCancel} />
      <EmergencyConfirmModal
        isOpen={isEmergencyConfirmModalOpen}
        onOk={onEmergencyConfirmModalOk}
        onCancel={onEmergencyConfirmModalCancel}
      />
      <EmergencyClearConfirmModal
        isOpen={isEmergencyClearConfirmModalOpen}
        onOk={onEmergencyClearConfirmModalOk}
        onCancel={onEmergencyClearConfirmModalCancel}
      />
    </>
  );
};

export default Systems;
