import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getProfile } from '../../api/profile';
import CommonHead from '../../components/commonHead';
import Layout from '../../components/layout';
import { getProfileUrl, getSettingsUrl } from '../../constants/api';
import { paths } from '../../constants/paths';
import { useAppDispatch } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { setProfile, setSettings } from '../../store/slices/profile';
import { IProfile, IProfilePermission, ISettings } from '../../typings/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import Page404 from '../404';
import DeviceEvent from '../deviceEvent';
import FaqPage from '../help/faq';
import FaqPageItem from '../help/faq/item';
import InstructionsPage from '../help/instructions';
import InstructionsPageItem from '../help/instructions/item';
import Subscribers from '../subscribers';
import SubscriberItem from '../subscribers/item';
import SubscribersNew from '../subscribers/new';
import Systems from '../systems';
import SystemItem from '../systems/item';
import SystemsNew from '../systems/new';
import TariffsPage from '../management/tariffs';
import TariffsCostPage from '../management/tariffsCost';
import PaymentsPage from '../management/payments';
import PaymentInfo from '../management/payments/item';
import SubscriptionInfo from '../management/subscriptions/item';
import SubscriptionsPage from '../management/subscriptions';
import OrganizationInfo from '../organization/information';
import SubscribersFaqPage from '../management/faq';
import RolesPage from '../roles';
import RolePage from '../roles/item';
import { sidebar } from '../../constants/sidebar';
import UserPage from '../users/item';
import UsersPage from '../users';
import DispatcherPanel from '../dispatcherPanel';
import FlatsPage from '../flats';
import FlatPage from '../flats/item';
import DevicePage from '../dispatcherPanel/item';
import DeviceEventItem from '../dispatcherPanel/eventItem';
import DispatcherPanelPlanItem from '../dispatcherPanel/planItem';
import FeedbackTablePage from '../feedback';
import FeedbackPage from '../feedback/item';
import { getRequest } from '../../api';
import CategoryGuidePage from '../guide/category';
import MailingPage from '../mailing';
import MailingPageItem from '../mailing/item';
import DispatcherViewCenter from '../dispatcherViewCenter';
import EmergencyReasons from '../guide/emergencyReasons';

const Root: FC = () => {
  const dispatch = useAppDispatch();
  const { data: profile, sendRequest: sendRequestProfile, loading } = useApi<IProfile>(getProfile);

  const { data: settings, sendRequest: sendRequestSettings, loading: loadingSettings } = useApi<ISettings>(getRequest);

  useEffect(() => {
    sendRequestProfile(getProfileUrl());
    sendRequestSettings(getSettingsUrl());
  }, []);

  useEffect(() => {
    if (profile?.id) {
      dispatch(setProfile(profile));
    }
  }, [profile]);

  useEffect(() => {
    if (settings) {
      dispatch(setSettings(settings));
    }
  }, [settings]);

  const defaultNavigateUrl = useMemo(() => {
    const viewPages = profile?.permissions?.filter((permission) => permission.view) || [];
    let page;
    for (const item of sidebar) {
      if (page) {
        break;
      }
      if (viewPages.find((elem) => elem.name === item.id)) {
        page = item.link || '';
        break;
      }
      if (item.categoryItems?.length) {
        for (const category of item.categoryItems) {
          if (viewPages.find((elem) => elem.name === category.id)) {
            page = category.link || '';
            break;
          }
        }
      }
    }
    return page || paths.helpFaq;
  }, [profile]);

  const getRoute = useCallback((permission: IProfilePermission): JSX.Element | null => {
    switch (permission.name) {
      case ESidebarItemIds.objects: {
        return permission.view ? (
          <Route path="systems">
            <Route index element={<Systems />} />
            <Route path=":systemId" element={<SystemItem />} />
            {permission.create && <Route path="new" element={<SystemsNew />} />}
          </Route>
        ) : null;
      }
      case ESidebarItemIds.subscribers: {
        return permission.view ? (
          <Route path="subscribers">
            <Route index element={<Subscribers />} />
            <Route path=":subscriberId" element={<SubscriberItem />} />
            {permission.create && <Route path="new" element={<SubscribersNew />} />}
          </Route>
        ) : null;
      }
      case ESidebarItemIds.logs: {
        return permission.view ? <Route path="device-event" element={<DeviceEvent />} /> : null;
      }
      case ESidebarItemIds.roles: {
        return permission.view ? (
          <Route path="roles">
            <Route index element={<RolesPage />} />
            <Route path=":instructionId" element={<RolePage />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.users: {
        return permission.view ? (
          <Route path="users">
            <Route index element={<UsersPage />} />
            <Route path=":userId" element={<UserPage />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.tariffs: {
        return permission.view ? <Route path="/management/tariffs" element={<TariffsPage />} /> : null;
      }
      case ESidebarItemIds.payments: {
        return permission.view ? (
          <>
            <Route path="/management/payments">
              <Route index element={<PaymentsPage />} />
              <Route path=":paymentId" element={<PaymentInfo />} />
            </Route>
            <Route path="/management/subscriptions">
              <Route index element={<SubscriptionsPage />} />
              <Route path=":subscriptionId" element={<SubscriptionInfo />} />
            </Route>
          </>
        ) : null;
      }
      case ESidebarItemIds.organizationInfo: {
        return permission.view ? <Route path="/organization/info" element={<OrganizationInfo />} /> : null;
      }
      case ESidebarItemIds.tariffCost: {
        return permission.view ? <Route path="/management/cost-tariffs" element={<TariffsCostPage />} /> : null;
      }
      case ESidebarItemIds.subscriberFAQ: {
        return permission.view ? <Route path="/management/faq" element={<SubscribersFaqPage />} /> : null;
      }
      case ESidebarItemIds.flatInfo: {
        return permission.view ? (
          <Route path="flats">
            <Route index element={<FlatsPage />} />
            <Route path=":flatId" element={<FlatPage />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.dispatcherPanel: {
        return permission.view ? (
          <Route path="dispatcher-panel">
            <Route index element={<DispatcherPanel />} />
            <Route path=":deviceId">
              <Route index element={<DevicePage />} />
              <Route path=":eventId" element={<DeviceEventItem />} />
              <Route path="plan" element={<DispatcherPanelPlanItem />} />
            </Route>
          </Route>
        ) : null;
      }
      case ESidebarItemIds.dispatcherViewCenter: {
        return permission.view ? (
          <Route path="dispatcher-viewcenter">
            <Route index element={<DispatcherViewCenter />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.feedback: {
        return permission.view ? (
          <Route path="feedback">
            <Route index element={<FeedbackTablePage />} />
            <Route path=":feedbackId" element={<FeedbackPage />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.helpFaq: {
        return permission.view ? (
          <Route path="help/faq">
            <Route index element={<FaqPage />} />
            <Route path=":questionId" element={<FaqPageItem />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.helpInstructions: {
        return permission.view ? (
          <Route path="help/instructions">
            <Route index element={<InstructionsPage />} />
            <Route path=":instructionId" element={<InstructionsPageItem />} />
          </Route>
        ) : null;
      }
      case ESidebarItemIds.feedbackCategory: {
        return permission.view ? <Route path="guide/category" element={<CategoryGuidePage />} /> : null;
      }
      case ESidebarItemIds.emergencyReason: {
        return permission.view ? <Route path="guide/emergency-reasons" element={<EmergencyReasons />} /> : null;
      }
      case ESidebarItemIds.mailing: {
        return permission.view ? (
          <>
            <Route path="mailing">
              <Route index element={<MailingPage />} />
            </Route>
            <Route path="mailing/email">
              <Route path=":itemId" element={<MailingPageItem />} />
            </Route>
            <Route path="mailing/sms">
              <Route path=":itemId" element={<MailingPageItem />} />
            </Route>
            <Route path="mailing/push">
              <Route path=":itemId" element={<MailingPageItem />} />
            </Route>
          </>
        ) : null;
      }
      default: {
        return null;
      }
    }
  }, []);

  const routes = useMemo(() => profile?.permissions?.map((item) => getRoute(item)), [getRoute, profile?.permissions]);

  if (loading || loadingSettings || !profile?.permissions) {
    return null;
  }

  return (
    <Layout profile={profile}>
      <CommonHead />
      <Routes>
        <Route path="/">
          <Route index element={<Navigate to={defaultNavigateUrl} replace />} />
          {routes}
        </Route>
        <Route path="/404">
          <Route index element={<Page404 />} />
        </Route>
        <Route path="*" element={<Navigate to={paths.page404} replace />} />
      </Routes>
    </Layout>
  );
};

export default Root;
