import { ReactNode } from 'react';
import { IAccessPoint, TreeNodeType, ITreeNode, ICameraPoint } from '../../../typings/treeNode';
import { ISettings } from '../../../typings/profile';

export interface ISelectedSchemaEntity {
  originalKey: string;
  object: ITreeNode | IAccessPoint | ICameraPoint | null;
  parents: string[];
  type: TreeNodeType | null;
  parent: ITreeNode | null;
  nodeIndex: number;
}

export interface ITreeNodeModal {
  isOpen?: boolean;
  editObject?: null | ITreeNode;
  onCreate?: (node: ITreeNode) => void;
  onEdit?: (node: ITreeNode) => void;
  onCancel?: () => void;
  path?: paper.Path | null;
  getLayout?: (path: paper.Path, title?: string) => string;
  parent?: ISelectedSchemaEntity;
  settings?: ISettings;
  localSchema?: ITreeNode[];
}

export enum EVisualizerModes {
  create = 'create',
  edit = 'edit',
  default = 'default',
  deviceShow = 'deviceShow',
}

export enum ESchemaButtonTypes {
  default = 'default',
  extendedCreation = 'extendedCreation',
  extendedRanges = 'extendedRanges',
  range = 'range',
}

export interface IButtonData {
  id: TreeNodeType;
  label: string;
  type: ESchemaButtonTypes;
  key: string;
  isDisabled?: boolean;
  value?: any;
  onClick?: () => void;
  onChange?: (a: any) => void;
  onOpenModal: () => void;
  onStartCreate: (mode: IVisualizerMode) => void;
}

export enum ETypesOfCreation {
  freeContour = 'freeContour',
  finishedFigure = 'finishedFigure',
}

export enum ETypesEdit {
  all = 'all',
  onlyShape = 'onlyShape',
  onlyData = 'onlyData',
}

export interface IVisualizerMode {
  status: EVisualizerModes;
  nodeType: null | TreeNodeType;
  typeOfCreation: null | ETypesOfCreation;
  shape: null | IShape;
  editType: null | ETypesEdit;
}

export enum ETypeShapes {
  path = 'path',
  circle = 'circle',
}

export interface IShape {
  element: ReactNode | null;
  type: ETypeShapes;
  points: number[][];
}

export interface INonUniqueSerialNumberData {
  accessPointName: string;
  address: string;
  deviceName: string;
  id: string;
  serialNumber: string;
  companyName?: string;
  objectId?: string;
  objectName?: string;
}

export enum ESchemaModals {
  accessZone = 'accessZone',
  building = 'building',
  section = 'section',
  accessPoint = 'accessPoint',
  nonUniqueSerialNumber = 'nonUniqueSerialNumber',
  nonUniqueMacAddress = 'nonUniqueMacAddress',
  errorsSerialNumbers = 'errorsSerialNumbers',
  background = 'background',
  camera = 'camera',
}

export interface ISchemaBackground {
  img: string;
  layout: string;
  isRescaled: boolean;
}

export interface ISchemaOpacity {
  background: number;
  active: number;
  inactive: number;
}
