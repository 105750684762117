import React, { FC } from 'react';
import { IInfoTip } from './types';

const InfoTip: FC<IInfoTip> = (props) => {
  const { item } = props;
  return item ? (
    <div className="info-tip">
      <div className="info-tip__name">{item.name}</div>
    </div>
  ) : null;
};

export default InfoTip;
