import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import CommonHead from '../../../components/commonHead';
import { setHeaderTitle } from '../../../store/slices/header';
import EmergencyReasonTable from '../../../components/guide/emergencyReason/emergencyReasonTable';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import FiltersTool from '../../../components/ui/filtersTool';
import { EFilterTool } from '../../../typings/filtersTool';
import { IEmergencyReason } from '../../../typings/guide';
import { IApiResponse } from '../../../typings/api';
import { useApi } from '../../../hooks/useApi';
import { IEmergencyReasonApiSettings } from '../../../api/emergencyReasons/types';
import { getEmergencyReasonApiSettings } from '../../../api/emergencyReasons/config';
import { postRequest } from '../../../api';
import { getEmergencyReasonListUrl } from '../../../constants/api';
import EmergencyReasonModal from '../../../components/guide/emergencyReason/emergencyReasonModal';
import EmergencyReasonDeleteModal from '../../../components/guide/emergencyReason/emergencyReasonDeleteModal';

const EmergencyReasons: FC = () => {
  const dispatch = useAppDispatch();

  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.emergencyReason));

  const {
    data: texts,
    sendRequest: getTexts,
    loading: textsLoading,
  } = useApi<IApiResponse<IEmergencyReason>>(postRequest);

  const [apiSettings, setApiSettings] = useState<IEmergencyReasonApiSettings>(getEmergencyReasonApiSettings());

  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, setEditRow] = useState<IEmergencyReason | null>(null);

  const requestData = useCallback(
    async (reqSettings: IEmergencyReasonApiSettings = apiSettings) => {
      setApiSettings(reqSettings);
      await getTexts(getEmergencyReasonListUrl(), reqSettings, {});
    },
    [apiSettings, getTexts]
  );

  useEffect(() => {
    requestData();
    dispatch(setHeaderTitle('Причины ЧС'));
  }, [dispatch, requestData]);

  const handleOnSearch = useCallback(
    async (value: string) => {
      const newApiSettings: IEmergencyReasonApiSettings = { ...apiSettings, page: 0, search: value };
      requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IEmergencyReasonApiSettings = { ...apiSettings, page: 0, search: values.search || '' };
      requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const onCreateClick = useCallback(() => {
    setEditRow(null);
    setModalOpen(true);
  }, [setEditRow, setModalOpen]);

  const onEditRow = useCallback(
    (rowId: string, row: IEmergencyReason) => {
      setEditRow(row);
      setModalOpen(true);
    },
    [setEditRow, setModalOpen]
  );

  const onDeleteRow = useCallback((rowId: string, row: IEmergencyReason) => {
    setEditRow(row);
    setDeleteModalOpen(true);
  }, []);

  const handleOnDeleted = useCallback(() => {
    setDeleteModalOpen(false);
    setEditRow(null);
    requestData();
  }, [requestData]);

  const onModalOk = useCallback(() => {
    setModalOpen(false);
    setEditRow(null);
  }, [setModalOpen, setEditRow]);

  return (
    <>
      <CommonHead seo={{ title: 'Причины ЧС' }} />
      <div className="emergency-reasons">
        <FiltersTool
          setValuesFromUrl={setValuesFromUrl}
          rows={[
            [
              {
                type: EFilterTool.search,
                id: 'search',
                props: {
                  placeholder: 'Поиск по названию',
                  value: apiSettings.search,
                  showClear: true,
                  onSearch: handleOnSearch,
                },
              },
              {
                type: EFilterTool.button,
                hidden: !permissionObject?.create,
                props: {
                  onClick: onCreateClick,
                  children: 'Создать причину ЧС',
                },
              },
            ],
          ]}
        />
        <EmergencyReasonTable
          permissions={permissionObject}
          texts={texts?.items || []}
          loading={textsLoading}
          pageSize={texts?.pageSize}
          total={texts?.totalCount}
          currentPage={texts?.page}
          onChangePage={handleOnChangeTablePage}
          isSearch={!!apiSettings.search}
          onEditRow={onEditRow}
          onDeleteRow={onDeleteRow}
        />
        <EmergencyReasonModal
          editItem={editRow}
          isOpen={isModalOpen}
          onOk={onModalOk}
          onCancel={() => setModalOpen(false)}
        />
        <EmergencyReasonDeleteModal
          item={editRow}
          isOpen={isDeleteModalOpen}
          onDeleted={handleOnDeleted}
          onCancel={() => setDeleteModalOpen(false)}
        />
      </div>
    </>
  );
};

export default EmergencyReasons;
