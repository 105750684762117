export interface IApiResponse<T> {
  items: T[];
  totalCount: number;
  page: number;
  pageSize: number;
}

export interface IApiError {
  errorCodes?: string;
  message?: string;
  title?: string;
  stackTrace?: string;
  additionalInformation?: any[];
}

export interface IApiSortField<T> {
  field: keyof T;
  descending: boolean;
}

export interface IApiSettings<T> {
  sortFields: IApiSortField<T>[];
  page: number;
  count: number;
  search: string;
}

export enum ApiFilterCommand {
  contain = 'Con',
  equal = 'Eq',
}

export enum ApiFilterOperator {
  and = 'And',
  or = 'Or',
}

export interface IApiFilter<T> {
  name: keyof T | string;
  command: ApiFilterCommand;
  search: string;
  operator: ApiFilterOperator;
}

export interface IApiExtendSetting<T> extends IApiSettings<T> {
  filters: IApiFilter<T>[];
  sort: string;
}

export interface IApiParams<T> extends Omit<IApiSettings<T>, 'sortFields'> {
  sort: { name: keyof T }[];
}

export enum ApiQueryOrder {
  asc = 1,
  desc = 2,
}

export interface IApiQueryParams<T = {}> {
  page?: number;
  pageSize?: number;
  count?: number;
  search?: string;
  sortField?: keyof T;
  orderBy?: ApiQueryOrder;
}

export interface IErrorResponseData {
  message: string;
}
