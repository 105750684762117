import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import Message from '../../components/message';

export const defaultResponseParser = (response: any) => response;

export const useApi = <T = any>(
  axiosMethod: (...args: any[]) => Promise<T | AxiosError>,
  responseParser: (response: any) => T = defaultResponseParser,
  needShowModal = true
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = useCallback<typeof axiosMethod>(
    async (...params: Parameters<typeof axiosMethod>) => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosMethod(...params);
        const result = responseParser(response);
        setData(result as T);
        return result;
      } catch (err: any) {
        setError(err);
        if (needShowModal) {
          if (err?.response?.data?.message) {
            Message.error({
              content: err?.response?.data?.message,
            });
          } else if (err.message) {
            Message.error({
              content: err.message,
            });
          }
        }
        return err;
      } finally {
        setLoading(false);
      }
    },
    [axiosMethod, needShowModal, responseParser]
  );

  return { data, error, loading, sendRequest };
};
