import React, { FC } from 'react';

const DispatcherViewCenterIcon: FC = () => (
  <svg
    fill="#fafafa"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
  >
    <path d="M31.371,27.929 L21.371,31.929 C21.253,31.976 21.127,32.000 21.000,32.000 L17.000,32.000 L15.000,32.000 C14.448,32.000 14.000,31.553 14.000,31.000 L14.000,30.000 C14.000,29.447 14.448,29.000 15.000,29.000 L17.000,29.000 C17.553,29.000 18.000,29.447 18.000,30.000 L20.808,30.000 L26.151,27.863 C24.912,27.494 24.000,26.357 24.000,25.000 L24.000,19.000 C24.000,17.346 25.346,16.000 27.000,16.000 L29.000,16.000 C29.352,16.000 29.686,16.072 30.000,16.184 L30.000,16.000 C30.000,8.280 23.720,2.000 16.000,2.000 C8.280,2.000 2.000,8.280 2.000,16.000 L2.000,16.184 C2.314,16.072 2.648,16.000 3.000,16.000 L5.000,16.000 C6.654,16.000 8.000,17.346 8.000,19.000 L8.000,25.000 C8.000,26.654 6.654,28.000 5.000,28.000 L3.000,28.000 C1.346,28.000 0.000,26.654 0.000,25.000 L0.000,19.000 L0.000,16.000 C0.000,7.178 7.178,-0.000 16.000,-0.000 C24.822,-0.000 32.000,7.178 32.000,16.000 L32.000,17.000 L32.000,19.000 L32.000,25.000 L32.000,27.000 C32.000,27.409 31.751,27.776 31.371,27.929 ZM2.000,25.000 C2.000,25.552 2.449,26.000 3.000,26.000 L5.000,26.000 C5.551,26.000 6.000,25.552 6.000,25.000 L6.000,19.000 C6.000,18.448 5.551,18.000 5.000,18.000 L3.000,18.000 C2.449,18.000 2.000,18.448 2.000,19.000 L2.000,25.000 ZM29.000,18.000 L27.000,18.000 C26.448,18.000 26.000,18.448 26.000,19.000 L26.000,25.000 C26.000,25.552 26.448,26.000 27.000,26.000 L29.000,26.000 C29.552,26.000 30.000,25.552 30.000,25.000 L30.000,19.000 C30.000,18.448 29.552,18.000 29.000,18.000 Z" />
  </svg>
);

export default DispatcherViewCenterIcon;
