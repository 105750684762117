import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IEmergencyModalProps } from './types';
import { IEmergencyParams, IEmergencySettings } from '../../../typings/dispatcherPanel';
import Input from '../../ui/input';
import { InputType } from '../../ui/input/types';
import { useApi } from '../../../hooks/useApi';
import { getRequest, postRequest } from '../../../api';
import { getEmergencySettingsUrl, getEmergencyReasonListUrl } from '../../../constants/api';
import Select from '../../ui/select';
import { IEmergencyReason } from '../../../typings/guide';
import { ISelectOption } from '../../ui/select/types';
import { IApiResponse } from '../../../typings/api';

const EmergencyModal: FC<IEmergencyModalProps> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  const { sendRequest: getEmergencySettings } = useApi<IEmergencySettings>(getRequest);
  const { data: emergencyReasons, sendRequest: getEmergencyReasonList } =
    useApi<IApiResponse<IEmergencyReason>>(postRequest);

  const [duration, setDuration] = useState('');
  const [reasonId, setReasonId] = useState('');
  const [customNotificationText, setCustomNotificationText] = useState('');

  const activateClick = () => {
    const data: IEmergencyParams = {
      duration: parseInt(duration, 10),
      reasonId: reasonId,
      customNotificationText: customNotificationText,
    };
    onOk(data);
  };

  const loadNotificationList = useCallback(async () => {
    await getEmergencyReasonList(getEmergencyReasonListUrl(), { page: 1, count: 0 });
  }, [getEmergencyReasonList]);

  useEffect(() => {
    if (!isOpen) return;
    loadNotificationList();
    getEmergencySettings(getEmergencySettingsUrl()).then((data) => {
      const settings = data as IEmergencySettings;
      setDuration(settings.duration.toString());
    });
  }, [getEmergencySettings, isOpen, loadNotificationList]);

  const options = useMemo(
    () => emergencyReasons?.items.map<ISelectOption>((item) => ({ value: item.id, title: item.name })),
    [emergencyReasons]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Активация режима ЧС"
      width={416}
      showCloseIcon
      wrapClassName="emergency-modal__wrapper"
      centered
      footer={
        <div className="emergency-modal__button-container">
          <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
          <Button
            size={ButtonSize.small}
            disabled={!(duration && (reasonId || customNotificationText))}
            onClick={activateClick}
          >
            Активировать
          </Button>
        </div>
      }
    >
      <Input
        title="Длительность, ч"
        inputType={InputType.number}
        minValue={1}
        maxValue={100}
        isRequired
        value={duration}
        placeholder="Длительность, ч"
        onChange={setDuration}
      />
      <Select
        title="Причина"
        options={options}
        value={reasonId}
        showClear
        onChange={(value) => setReasonId(value as string)}
      />
      <Input
        title="Дополнительный текст"
        value={customNotificationText}
        maxLength={255}
        placeholder="Дополнительный текст для уведомления"
        onChange={setCustomNotificationText}
      />
    </Modal>
  );
};

export default EmergencyModal;
