import React, { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  IHardwareRevision,
  IHardwareType,
  IHardwareVersion,
} from '../../../../../../../typings/systems/hardwareSettings';
import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import NotificationModal from '../../../../../../ui/notificationModal';
import RadioGroup from '../../../../../../ui/radioGroup';
import { ERadioGroupDirection, IRadioGroupOption } from '../../../../../../ui/radioGroup/types';
import Select from '../../../../../../ui/select';
import { ISelectOption } from '../../../../../../ui/select/types';
import { IFiveThousandOptions } from './types';
import { emptyFieldErrorText } from '../../advancedOptions/types';
import { useApi } from '../../../../../../../hooks/useApi';
import { getRequest, postRequest } from '../../../../../../../api';
import {
  connectDeviceUrl,
  disconnectDeviceUrl,
  getHardwareTypesUrl,
  getRevisionVersionsUrl,
  getRevisionsListUrl,
} from '../../../../../../../constants/api';
import SerialNumberInput from '../../../../../../ui/input/serialNumberInput';
import { defaultFiveThousandOptions } from '../../../../config';
import Button from '../../../../../../ui/button';
import { ButtonType } from '../../../../../../ui/button/types';
import { TreeNodeType } from '../../../../../../../typings/treeNode';
import { ESystemTabErrorCodes } from '../../../../../../../api/systems/types';

const FiveThousandOptions: FC<IFiveThousandOptions> = (props) => {
  const {
    isAdmin = false,
    nodeType = TreeNodeType.device,
    options = defaultFiveThousandOptions,
    settings = null,
    onChange = () => {},
    permissions = {},
    deviceCategories = [],
    handleOnChangeDeviceType = () => {},
    changeSettings = () => {},
    handleOnSave = () => false,
    requestData = () => {},
    systemId,
    closeConfirm = () => {},
    setConfirmData = () => {},
    isDevice = true,
    isConnected = false,
  } = props;

  const { sendRequest: changeConnection, loading: changeConnectionLoading } = useApi<IHardwareType[]>(postRequest);
  const {
    data: hardwareDeviceTypes,
    sendRequest: getDeviceTypes,
    loading: deviceTypesLoading,
  } = useApi<IHardwareType[]>(getRequest);
  const {
    data: revisionsList,
    sendRequest: getAllRevisions,
    loading: revisionLoading,
  } = useApi<IHardwareRevision[]>(getRequest);
  const {
    data: versionsList,
    sendRequest: getAllVersions,
    loading: versionsLoading,
  } = useApi<IHardwareVersion[]>(getRequest);

  const [deviceCategoryModal, setDeviceCategoryModal] = useState<{
    value?: string;
    isOpen: boolean;
  }>({ isOpen: false });

  const updateCategoryDevice = useCallback(
    (deviceCategoryId: string) => {
      onChange(deviceCategories?.find((item) => item.id === deviceCategoryId) || null, 'deviceCategory');
    },
    [deviceCategories, onChange]
  );

  const handleOnChangeCategoryDevice = useCallback(
    (deviceCategoryId: string) => {
      if (options.selectedDeviceTypeId && settings) {
        setDeviceCategoryModal({ value: deviceCategoryId, isOpen: true });
      } else {
        updateCategoryDevice(deviceCategoryId);
      }
    },
    [options.selectedDeviceTypeId, settings, updateCategoryDevice]
  );

  const handleOnCloseCategoryDeviceModal = useCallback(() => {
    setDeviceCategoryModal({ isOpen: false });
  }, []);

  const handleOnOkCategoryDeviceModal = useCallback(() => {
    if (deviceCategoryModal.value) {
      onChange(null, 'selectedDeviceTypeId');
      updateCategoryDevice(deviceCategoryModal.value);
      if (settings) {
        changeSettings({ ...settings, accessPointTypeParams: null });
      }
    }
    setDeviceCategoryModal({ isOpen: false });
  }, [changeSettings, deviceCategoryModal.value, onChange, settings, updateCategoryDevice]);

  const onGetTypes = useCallback(async () => {
    if (!hardwareDeviceTypes?.length) {
      await getDeviceTypes(getHardwareTypesUrl(), { params: { objectId: systemId } });
    }
  }, [getDeviceTypes, hardwareDeviceTypes?.length, systemId]);

  const onGetRevisions = useCallback(
    async (selectedDeviceTypeId = options.selectedDeviceTypeId.value || '') => {
      const res = await getAllRevisions(getRevisionsListUrl(selectedDeviceTypeId));
      return res;
    },
    [getAllRevisions, options.selectedDeviceTypeId.value]
  );

  const onGetVersions = useCallback(
    async (
      selectedDeviceTypeId = options.selectedDeviceTypeId.value || '',
      selectedDeviceRevisionId = options.selectedDeviceRevisionId.value || ''
    ) => {
      const res = await getAllVersions(getRevisionVersionsUrl(selectedDeviceTypeId, selectedDeviceRevisionId));
      return res;
    },
    [options.selectedDeviceTypeId.value, options.selectedDeviceRevisionId.value, getAllVersions]
  );

  const onChangeRevision = useCallback(
    (e: string | number) => handleOnChangeDeviceType(options.selectedDeviceTypeId.value, e.toString(), null),
    [handleOnChangeDeviceType, options.selectedDeviceTypeId.value]
  );

  const onChangeVersion = useCallback(
    (e: string | number) =>
      handleOnChangeDeviceType(
        options.selectedDeviceTypeId.value,
        options.selectedDeviceRevisionId.value,
        e.toString()
      ),
    [handleOnChangeDeviceType, options.selectedDeviceTypeId.value, options.selectedDeviceRevisionId.value]
  );

  const onChangeType = useCallback(
    async (e: string | number) => {
      const revisions = (await onGetRevisions(e.toString())) as IHardwareRevision[];
      if (revisions?.length === 1) {
        onChangeRevision(revisions[0].id);
        const versions = (await onGetVersions(e.toString(), revisions[0].id)) as IHardwareVersion[];
        if (versions?.length === 1) {
          onChangeRevision(versions[0].id);
          handleOnChangeDeviceType(e.toString(), revisions[0].id, versions[0].id);
        } else {
          handleOnChangeDeviceType(e.toString(), revisions[0].id, null);
        }
      } else {
        handleOnChangeDeviceType(e.toString(), null, null);
      }
    },
    [handleOnChangeDeviceType, onChangeRevision, onGetRevisions, onGetVersions]
  );

  const onChangeConnection = useCallback(async () => {
    closeConfirm();
    const resError: any = await changeConnection(
      isConnected
        ? disconnectDeviceUrl(systemId || '', settings?.id || '')
        : connectDeviceUrl(systemId || '', settings?.id || ''),
      {}
    );
    if (resError?.response?.data?.errorCodes === ESystemTabErrorCodes.SyncValidationError) {
      setConfirmData({
        isOpen: true,
        description: resError?.response?.data?.message,
        buttons: [
          {
            label: 'Понятно',
            type: ButtonType.primary,
            onClick: closeConfirm,
          },
        ],
      });
    } else {
      requestData();
    }
  }, [changeConnection, closeConfirm, isConnected, requestData, setConfirmData, settings?.id, systemId]);

  const tryOnChangeConnection = useCallback(async () => {
    if (await handleOnSave()) {
      if (isConnected) {
        setConfirmData({
          isOpen: true,
          description: 'Вы уверены, что хотите отвязать оборудование?',
          buttons: [
            {
              label: 'Да',
              type: ButtonType.primary,
              onClick: () => onChangeConnection(),
            },
            {
              label: 'Нет',
              type: ButtonType.secondary,
              onClick: closeConfirm,
            },
          ],
        });
      } else {
        await onChangeConnection();
      }
    }
  }, [closeConfirm, handleOnSave, isConnected, onChangeConnection, setConfirmData]);

  const filteredHardwareDeviceTypes = useMemo<IHardwareType[]>(
    () => hardwareDeviceTypes?.filter((item: any) => item.deviceCategoryId === options.deviceCategory.value?.id) || [],
    [hardwareDeviceTypes, options.deviceCategory.value?.id]
  );

  const deviceCategoriesOptions = useMemo(
    () =>
      deviceCategories?.map<IRadioGroupOption>((category) => ({
        title: category.displayName || '',
        value: category.id,
      })) || [],
    [deviceCategories]
  );

  const hardwareDeviceTypesData = useMemo<ISelectOption[]>(
    () =>
      filteredHardwareDeviceTypes.length
        ? filteredHardwareDeviceTypes.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: `${object.name} ${object.code}`,
          }))
        : options.selectedDeviceTypeId.value
        ? [
            {
              value: options.selectedDeviceTypeId.value || '',
              title: `${settings?.selectedDeviceType || ''} ${settings?.selectedDeviceTypeCode || ''}`,
            },
          ]
        : [],
    [
      filteredHardwareDeviceTypes,
      options.selectedDeviceTypeId.value,
      settings?.selectedDeviceType,
      settings?.selectedDeviceTypeCode,
    ]
  );

  const revisionsOptions = useMemo(
    () =>
      revisionsList?.length
        ? revisionsList?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.name,
          }))
        : options.selectedDeviceRevisionId.value
        ? [
            {
              value: options.selectedDeviceRevisionId.value || '',
              title: settings?.selectedDeviceRevision || '',
            },
          ]
        : [],
    [options.selectedDeviceRevisionId.value, revisionsList, settings?.selectedDeviceRevision]
  );

  const versionsOptions = useMemo(
    () =>
      versionsList?.length
        ? versionsList?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.name.toString(),
          }))
        : options.selectedDeviceVersionId.value
        ? ([
            {
              value: options.selectedDeviceVersionId.value || '',
              title: settings?.selectedDeviceVersion || '',
            },
          ] as ISelectOption[])
        : [],
    [options.selectedDeviceVersionId.value, settings?.selectedDeviceVersion, versionsList]
  );

  const connectTypeOptions = useMemo(
    () => [
      {
        value: true,
        title: 'Локальное',
      },
      {
        value: false,
        title: 'Удаленное',
      },
    ],
    []
  );

  return (
    <div className="main-options">
      <NotificationModal
        isOpen={deviceCategoryModal.isOpen}
        title="При изменении категории оборудования указанные настройки оборудования будут удалены. Вы хотите внести изменение?"
        onOk={handleOnOkCategoryDeviceModal}
        onCancel={handleOnCloseCategoryDeviceModal}
        onClose={handleOnCloseCategoryDeviceModal}
        okButtonText="Да"
        cancelButtonText="Нет"
      />
      <div className="main-options__container">
        <div className="main-options__options main-options__options_alt">
          <Input
            title="Название оборудования"
            placeholder="Название оборудования"
            isRequired
            value={options.name.value || ''}
            onChange={(e: string) => onChange(e, 'name')}
            status={options.name.status}
            errorText={options.name.errorText}
            maxLength={100}
            inputType={InputType.text}
            disabled={
              !permissions.edit ||
              nodeType === TreeNodeType.commutator ||
              (isConnected && nodeType === TreeNodeType.securityPostConsole)
            }
            isDisabledStyle
          />
          <SerialNumberInput
            title="Серийный номер"
            placeholder="Серийный номер"
            value={options.serialNumber.value || ''}
            onChange={(e: string) => onChange(e, 'serialNumber')}
            status={options.serialNumber.status}
            errorText={options.serialNumber.errorText}
            maxLength={20}
            inputType={InputType.text}
            disabled={!permissions.edit || isConnected}
            isDisabledStyle
          />
          <Select
            title="Тип подключения"
            placement="topLeft"
            value={options.isLocalConnection.value}
            onChange={(e) => onChange(!!e, 'isLocalConnection')}
            disabled={!permissions.edit || isConnected}
            isRequired
            options={connectTypeOptions}
            isError={options.isLocalConnection.status === InputStatus.error}
            errorText={options.isLocalConnection.errorText}
            isDisabledStyle
          />
          <div />
        </div>
        <div className="main-options__options main-options__options_alt">
          <Input
            title="Адрес оборудования"
            placeholder="Адрес оборудования"
            value={options.netAddress.value}
            status={options.netAddress.status}
            errorText={options.netAddress.errorText}
            inputType={InputType.number}
            maxLength={8}
            isRequired
            onChange={(e: string) => onChange(e === '' ? null : Number(e), 'netAddress')}
            disabled={!permissions.edit || isConnected}
            isDisabledStyle
          />
          <Input
            title="Текущий адрес"
            placeholder="Текущий адрес"
            value={options.currentNetAddress.value}
            status={options.currentNetAddress.status}
            errorText={options.currentNetAddress.errorText}
            inputType={InputType.number}
            maxValue={126}
            minValue={0}
            textInfo="Число от 0 до 126"
            onChange={(e: string) => onChange(e === '' ? null : Number(e), 'currentNetAddress')}
            disabled={!permissions.edit || isConnected}
            isDisabledStyle
          />
          <Input
            title="Текущий пароль"
            placeholder="Текущий пароль"
            value={options.netPassword.value}
            status={options.netPassword.status}
            errorText={options.netPassword.errorText}
            inputType={InputType.number}
            isRequired
            maxLength={8}
            onChange={(e: string) => onChange(e === '' ? null : Number(e), 'netPassword')}
            disabled={!permissions.edit || isConnected}
            isDisabledStyle
          />
        </div>

        <div
          className={classNames('main-options__options', {
            'main-options__options_alt': !isDevice,
            'main-options__options_gap': isDevice,
          })}
        >
          {isDevice && (
            <div className="main-options__radio-container">
              <RadioGroup
                title="Категория оборудования"
                options={deviceCategoriesOptions}
                value={options.deviceCategory.value?.id || ''}
                onChange={(value) => handleOnChangeCategoryDevice(value)}
                direction={ERadioGroupDirection.horizontal}
                disabled={!permissions.edit || isConnected || (!settings?.isNew && options.selectedDeviceTypeId.value)}
              />
            </div>
          )}

          <Select
            lengthLimit={false}
            title="Тип оборудования"
            placeholder="Тип оборудования"
            value={options.selectedDeviceTypeId.value || ''}
            onChange={onChangeType}
            disabled={!permissions.edit || isConnected || (!settings?.isNew && options.selectedDeviceTypeId.value)}
            isDisabledStyle
            onLoadData={onGetTypes}
            loading={deviceTypesLoading}
            placement="topLeft"
            isRequired
            options={hardwareDeviceTypesData}
            isError={options.selectedDeviceTypeId.status === InputStatus.error}
            errorText={
              options.selectedDeviceTypeId.status === InputStatus.error
                ? options.selectedDeviceTypeId.errorText || emptyFieldErrorText
                : ''
            }
          />
          {options.selectedDeviceTypeId.value && (
            <>
              <Select
                lengthLimit={false}
                loading={revisionLoading}
                title="Ревизия"
                placement="topLeft"
                value={options.selectedDeviceRevisionId.value || ''}
                onChange={onChangeRevision}
                disabled={!permissions.edit || isConnected}
                onLoadData={onGetRevisions}
                isRequired
                options={revisionsOptions}
                isDisabledStyle
                isError={options.selectedDeviceRevisionId.status === InputStatus.error}
                errorText={
                  options.selectedDeviceRevisionId.status === InputStatus.error
                    ? options.selectedDeviceRevisionId.errorText || emptyFieldErrorText
                    : ''
                }
              />
              {options.selectedDeviceRevisionId.value && (
                <Select
                  lengthLimit={false}
                  loading={versionsLoading}
                  title="Версия"
                  placement="topLeft"
                  value={options.selectedDeviceVersionId.value || ''}
                  onChange={onChangeVersion}
                  disabled={!permissions.edit || isConnected}
                  isDisabledStyle
                  onLoadData={onGetVersions}
                  isRequired
                  options={versionsOptions}
                  isError={options.selectedDeviceVersionId.status === InputStatus.error}
                  errorText={
                    options.selectedDeviceVersionId.status === InputStatus.error
                      ? options.selectedDeviceVersionId.errorText || emptyFieldErrorText
                      : ''
                  }
                />
              )}
            </>
          )}
        </div>
        <div className="main-options__options-additionally-content">
          <Button
            disabled={isAdmin || !permissions.edit}
            loading={changeConnectionLoading}
            type={ButtonType.outline}
            onClick={tryOnChangeConnection}
          >
            {isConnected ? 'Отвязать оборудование' : 'Привязать оборудование'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FiveThousandOptions;
