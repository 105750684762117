import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CommonHead from '../../components/commonHead';
import { useAppDispatch } from '../../hooks/hooks';
import { setHeaderTitle } from '../../store/slices/header';

const DispatcherViewCenter: FC = () => {
  const dispatch = useAppDispatch();

  dispatch(setHeaderTitle('Панель диспетчера'));

  const renderContent = useCallback(() => {}, []);

  return (
    <>
      <CommonHead seo={{ title: 'Панель диспетчера' }} />
      {renderContent()}
    </>
  );
};

export default DispatcherViewCenter;
