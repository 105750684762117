import { LngLat } from '@yandex/ymaps3-types';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { AccessPointType, ITreeNode } from '../../typings/treeNode';
import { ISelectedSchemaEntity } from '../systemTabs/schemaTab/types';

export enum ObjectMapItemType {
  accessPoint = 'AccessPoint',
  camera = 'Camera',
}

export interface ISchemaMap {
  objectId: string;
  schema: ITreeNode[];
  selectedNode?: ISelectedSchemaEntity;
  displayedObjects?: CheckboxValueType[];
  onItemClick: (item: IMapItem) => void;
  onItemMoved: (item: IMapItem, coordinates: LngLat) => void;
}

export interface IObjectAddress {
  id: string;
  latitude: number | null;
  longitude: number | null;
  value: string;
}

export interface IMapItem {
  id: string;
  type: ObjectMapItemType;
  deviceType?: AccessPointType;
  name: string;
  latitude: number | null;
  longitude: number | null;
}

export interface IMapItemProps {
  item: IMapItem;
  isSelected: boolean;
  onClick: (item: IMapItem) => void;
  onMoved: (item: IMapItem, coordinates: LngLat) => void;
}

export interface IMapItemCreateResult {
  item: IMapItem;
  defaultCoordinates: boolean;
  nextItemIndex: number;
}
