export const ellipsisStringByLimit = (str: string, limitLength: number) => {
  if (str.length > limitLength) {
    return `${str
      .replace(/<.*?>/g, ' ')
      .replace(/ +/g, ' ')
      .substring(0, limitLength - 3)}...`;
  }
  return str;
};

export const parseCoordinates = (value?: string) => {
  if (!value) return [null, null];
  const tokens = value.split(',');
  if (tokens.length > 1) {
    return [parseFloat(tokens[0].trim()), parseFloat(tokens[1].trim())];
  }
  return [null, null];
};
