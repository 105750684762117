import React, { FC, useCallback, useEffect } from 'react';
import { IInformationPanel } from './types';
import { useApi } from '../../../hooks/useApi';
import { getRequestWithArrayInParams } from '../../../api';
import { dispatcherPanelObjectTreeUrl } from '../../../constants/api';
import { IDispatcherPanelObject } from '../../../typings/dispatcherPanel';
import InformationPanelItem from '../objectInfo/informationPanelItem';
import { selectAllOptionKey } from '../../../constants/select';

const InformationPanel: FC<IInformationPanel> = ({
  dispatcherPanelApiParams,
  setDispatcherPanelApiParams,
  isAdmin,
  onModal = () => {},
}) => {
  const { data, sendRequest, loading } = useApi<IDispatcherPanelObject[]>(getRequestWithArrayInParams);

  const getData = useCallback(() => {
    sendRequest(dispatcherPanelObjectTreeUrl(), {
      params: {
        ...dispatcherPanelApiParams,
        statuses: dispatcherPanelApiParams?.statuses
          ? dispatcherPanelApiParams?.statuses[0] === selectAllOptionKey
            ? []
            : dispatcherPanelApiParams.statuses
          : [],
      },
    });
  }, [dispatcherPanelApiParams, sendRequest]);

  useEffect(() => {
    if (isAdmin && !dispatcherPanelApiParams?.companyId) {
      return;
    }

    getData();
  }, [dispatcherPanelApiParams]);

  return (
    <div className="dispatcher-info">
      <div className="dispatcher-info__title">
        <div className="dispatcher-info__title-label">Информационная панель</div>
      </div>

      <div className="dispatcher-info__content">
        {!loading &&
          data?.map((item) => (
            <InformationPanelItem
              setDispatcherPanelApiParams={setDispatcherPanelApiParams}
              dispatcherPanelApiParams={dispatcherPanelApiParams}
              item={item}
              key={item.objectId}
              onModal={onModal}
            />
          ))}
      </div>
    </div>
  );
};

export default InformationPanel;
