import type { DirectoryTreeProps } from 'antd/es/tree';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LngLat } from '@yandex/ymaps3-types';
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Key } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { ESystemBaseDeviceSeries } from '../../../typings/systems/base';
import { ISystemTabComponent } from '../types';
import AccessPointModal from './accessPointModal';
import AccessZoneModal from './accessZoneModal';
import BuildingsModal from './buildingsModal';
import SectionModal from './sectionModal';
import {
  EVisualizerModes,
  IVisualizerMode,
  ISelectedSchemaEntity,
  IButtonData,
  ETypesEdit,
  INonUniqueSerialNumberData,
  ESchemaModals,
  ISchemaBackground,
  ISchemaOpacity,
} from './types';
import SchemaTree from './schemaTree';
import { defaultResponseParser, useApi } from '../../../hooks/useApi';
import { editSystem, getSystem } from '../../../api/systems';
import TabNavButtons from '../../tabs/tabNavButtons';
import { getObjectPlanUrl } from '../../../constants/api';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import UniversalModal from '../../ui/universalModal';
import { TPaperPath } from '../../schemaCanvas/types';
import { getLayout, getPointLayout } from '../../schemaCanvas/paper/paperItems/items';
import SchemaButtons from './schemaButtons';
import { IConfirmData } from '../../ui/universalModal/types';
import { IAccessPoint, ICameraPoint, IKeyNode, ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import SchemaCanvas from '../../schemaCanvas';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import { setContentLayoutIsFullScreen } from '../../../store/slices/layout';
import Message from '../../message';
import { defaultConfirm, deleteModal, editChoiceModal, saveChangesModal } from '../../ui/universalModal/config';
import { ESystemTabErrorCodes } from '../../../api/systems/types';
import NonUniqueSerialNumberModal from './nonUniqueSerialNumberModal';
import {
  findNonRemovableDevices,
  onSelectedTreeNode,
  recalculateRelatedPoints,
  sortNodeChildren,
} from './calculations';
import BackgroundModal from './backgroundModal';
import {
  defaultBackground,
  defaultDisplayedObjects,
  defaultMode,
  defaultSchemaOpacity,
  getBackgroundButtons,
  getCreateModeButtons,
  getOpacityButton,
  initSelectObject,
  treeNodeButtons,
  typeDependencies,
} from './constans';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import NonUniqueMacAddressModal from './nonUniqueMacAdressModal';
import CameraModal from './cameraModal';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Button from '../../ui/button';
import DownloadIcon from '../../../assets/svg/icons/download';
import SchemaPDFModal from './pdfModal';
import SchemaMap from '../../schemaMap';
import { IMapItem, ObjectMapItemType } from '../../schemaMap/types';
import { getTreeKey } from '../../../utils/tree';

const SystemSchemaTab: FC<ISystemTabComponent> = ({
  system,
  systemId = '',
  onNextTab = () => {},
  activeTabKey,
  tabId,
  updateAvailableTabs = () => {},
  isWasChange = false,
  setChange = () => {},
  repeatChangeTab = () => {},
  chancelChangeTab = () => {},
  clickedTab = '',
  permissions = {},
  nextTabIsAvailable,
  settings = {},
  isFiveThousandth,
}) => {
  const dispatch = useAppDispatch();

  const [openedModal, setOpenModal] = useState<ESchemaModals | null>(null);

  const [serialNumbersData, setSerialNumbersData] = useState([]);

  const [macAddressData, setMacAddressData] = useState([]);

  const [baseDeviceSeries] = useState<ESystemBaseDeviceSeries>(system?.deviceSeriesId || ESystemBaseDeviceSeries.D7000);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [selectedNode, setSelected] = useState<ISelectedSchemaEntity>({ ...initSelectObject });
  const selectedNodeCopy = useRef<ISelectedSchemaEntity>(selectedNode);

  const [localSchema, setLocalSchema] = useState<ITreeNode[]>([]);
  const editableSchema = useRef<ITreeNode[]>([]);

  const { data, sendRequest, loading: treeLoading } = useApi<ITreeNode[]>(getSystem);
  const {
    data: systemData,
    sendRequest: sendEditSystem,
    loading: editPlanLoading,
  } = useApi(editSystem, defaultResponseParser, false);

  const [editObject, setEditObject] = useState<IAccessPoint | ITreeNode | null>(null);

  const currentButtons = useRef<(IButtonData | null)[]>([]);

  const [currentMode, setCurrentMode] = useState<IVisualizerMode>(defaultMode);
  const modeCopy = useRef<IVisualizerMode>(defaultMode);
  const currentPath = useRef<TPaperPath | null>(null);

  const fullScreenElementRef = useRef<HTMLDivElement | null>(null);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const [background, setBackground] = useState<ISchemaBackground>({ ...defaultBackground });

  const [opacityValues, setOpacityValues] = useState<ISchemaOpacity>(defaultSchemaOpacity);

  const [displayedObjects, setDisplayedObjects] = useState<TreeNodeType[]>(defaultDisplayedObjects);

  const [pfdModalOpen, setPdfModalOpen] = useState(false);

  const [isMapVisible, setMapVisible] = useState(false);

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const haveBeenChanges = useCallback(() => !isWasChange && setChange(true), [isWasChange, setChange]);

  const setSelectedNode = useCallback((state: ISelectedSchemaEntity) => {
    selectedNodeCopy.current = state;
    setSelected(state);
  }, []);

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    if (clickedTab) {
      chancelChangeTab();
    }
  }, [setConfirmData, clickedTab, chancelChangeTab]);

  const setSchemas = useCallback(
    (schema: ITreeNode[]) => {
      schema.forEach((node) => sortNodeChildren(node));
      if (currentMode.status === EVisualizerModes.default && !isFiveThousandth) {
        recalculateRelatedPoints(schema);
      }
      editableSchema.current = JSON.parse(JSON.stringify(schema));
      setLocalSchema(schema);
    },
    [currentMode.status, isFiveThousandth]
  );

  useEffect(() => {
    if (tabId === activeTabKey) {
      sendRequest(getObjectPlanUrl(systemId));
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (data) {
      setSchemas(data);
      setChange(false);
    }
  }, [data]);

  useEffect(() => {
    if (systemData) {
      setSchemas(systemData);
      setChange(false);
    }
  }, [systemData]);

  const setMode = useCallback((mode: IVisualizerMode = defaultMode) => {
    modeCopy.current = mode;
    setCurrentMode(mode);
  }, []);

  const resetState = useCallback(() => {
    currentPath.current = null;
    setEditObject(null);
    setMode();
  }, [setMode]);

  const handleOnCloseModal = useCallback(() => {
    setOpenModal(null);
    resetState();
  }, [resetState]);

  const handleOnOpenModal = useCallback((modal: ESchemaModals | null) => setOpenModal(modal), []);

  const treeNodeButtonsMap: Map<TreeNodeType, IButtonData> = useMemo(
    () => treeNodeButtons(handleOnOpenModal, setMode, settings),
    [handleOnOpenModal, setMode, settings]
  );

  const setPath = useCallback(
    (path: TPaperPath | null = null, modalWasOpen = true) => {
      currentPath.current = path;
      if (
        !modalWasOpen &&
        path &&
        path.closed &&
        currentMode.nodeType &&
        (!currentMode.editType || currentMode.editType === ETypesEdit.all)
      ) {
        treeNodeButtonsMap?.get(currentMode.nodeType)?.onOpenModal();
      }
    },
    [currentMode.editType, currentMode.nodeType, treeNodeButtonsMap]
  );

  const handleOnSelectedTreeNode = useCallback(
    (originalKey: string): ISelectedSchemaEntity => {
      const state = onSelectedTreeNode(originalKey, editableSchema.current);
      setSelectedNode(state);
      return state;
    },
    [setSelectedNode]
  );

  const createTreeNode = useCallback(
    (value: ITreeNode) => {
      if (editableSchema.current) {
        if (selectedNode.object && 'childItems' in selectedNode.object) {
          selectedNode.object.childItems?.push(value);
        } else {
          editableSchema.current.push(value);
        }
        resetState();
        setChange(true);
        setSchemas(editableSchema.current);
      }
    },
    [resetState, selectedNode, setChange, setSchemas]
  );

  const createTreePoint = useCallback(
    (value: IAccessPoint) => {
      if (editableSchema.current && selectedNode.object && 'accessPoints' in selectedNode.object) {
        resetState();
        setChange(true);
        selectedNode.object.accessPoints?.push(value);
        setSchemas(editableSchema.current);
      }
    },
    [resetState, selectedNode, setChange, setSchemas]
  );

  const createCameraPoint = useCallback(
    (value: ICameraPoint) => {
      const object = selectedNode.object as ITreeNode;
      if (editableSchema.current) {
        resetState();
        setChange(true);
        if (object?.cameras) object.cameras?.push(value);
        else object.cameras = [value];
        setSchemas(editableSchema.current);
      }
    },
    [resetState, selectedNode, setChange, setSchemas]
  );

  const editActiveObject = useCallback(
    (object: ITreeNode | IAccessPoint | ICameraPoint, node = selectedNode) => {
      const { parent, nodeIndex, type } = node;
      if (parent) {
        if (type === TreeNodeType.accessPoint && parent.accessPoints) {
          parent.accessPoints[nodeIndex] = object as IAccessPoint;
        } else if (type === TreeNodeType.camera && parent.cameras) {
          parent.cameras[nodeIndex] = object as ICameraPoint;
        } else if (parent.childItems) {
          parent.childItems[nodeIndex] = object as ITreeNode;
        }
      } else if (editableSchema.current) {
        editableSchema.current[nodeIndex] = object as ITreeNode;
      }
      setSchemas(editableSchema.current);
      setChange(true);
      resetState();
    },
    [selectedNode, setSchemas, setChange, resetState]
  );

  const handleOnSave = useCallback(async () => {
    if (currentMode.status === EVisualizerModes.default && !isFiveThousandth) {
      recalculateRelatedPoints(editableSchema.current);
    }
    const resError = await sendEditSystem(getObjectPlanUrl(systemId), editableSchema.current);
    let errorData;
    if ('response' in resError && resError.response) {
      errorData = resError.response.data;
    }
    if (
      errorData?.errorCodes === ESystemTabErrorCodes.SerialNumberValidation ||
      errorData?.errorCodes === ESystemTabErrorCodes.SerialNumberFormatValidation
    ) {
      setSerialNumbersData(errorData.additionalInformation);
      setOpenModal(
        errorData?.errorCodes === ESystemTabErrorCodes.SerialNumberValidation
          ? ESchemaModals.nonUniqueSerialNumber
          : ESchemaModals.errorsSerialNumbers
      );
    } else if (errorData?.errorCodes === ESystemTabErrorCodes.CameraMacDuplicate) {
      setMacAddressData(errorData.additionalInformation);
      setOpenModal(ESchemaModals.nonUniqueMacAddress);
    }
    if (resError?.response?.data) {
      Message.error({
        content: resError.response.data.message || 'Не удалось сохранить',
      });
    } else {
      setChange(false);
      updateAvailableTabs();
      return true;
    }
    return false;
  }, [currentMode.status, isFiveThousandth, sendEditSystem, setChange, systemId, updateAvailableTabs]);

  const handleOnCancel = useCallback(() => {
    sendRequest(getObjectPlanUrl(systemId));
    resetState();
    setBackground({ ...defaultBackground });
    closeConfirm();
  }, [closeConfirm, resetState, sendRequest, systemId]);

  const handleOnSaveChanges = useCallback(
    (callback: () => void, needSave: boolean) => async () => {
      if (currentPath.current) {
        editActiveObject(
          {
            ...selectedNodeCopy.current.object,
            layout: getLayout(currentMode)(currentPath.current),
          } as ITreeNode,
          selectedNodeCopy.current
        );
      } else {
        resetState();
        closeConfirm();
      }
      if (needSave) {
        if (await handleOnSave()) {
          callback();
        }
      }
    },
    [closeConfirm, currentMode, editActiveObject, handleOnSave, resetState]
  );

  const handleOnResetChanges = useCallback(
    (callback: () => void, needSave: boolean) => async () => {
      if (needSave) {
        handleOnCancel();
      } else {
        resetState();
        closeConfirm();
      }
      callback();
    },
    [closeConfirm, handleOnCancel, resetState]
  );

  const checkModeStatus = useCallback(
    (callback: () => void, needSave = false) => {
      if (modeCopy.current.status !== EVisualizerModes.default || needSave) {
        setConfirmData(
          saveChangesModal(handleOnSaveChanges(callback, needSave), handleOnResetChanges(callback, needSave))
        );
      } else {
        callback();
      }
    },
    [setConfirmData, handleOnSaveChanges, handleOnResetChanges]
  );

  useEffect(() => {
    if (clickedTab && activeTabKey === tabId) {
      checkModeStatus(() => repeatChangeTab(), true);
    }
  }, [clickedTab]);

  const checkChanges = useCallback(
    (callBack: () => void) => {
      if (isWasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              handleOnSaveChanges(callBack, true)();
            },
            () => {
              closeConfirm();
              callBack();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, handleOnSaveChanges, isWasChange, setConfirmData]
  );

  const onClickedSidebarTabChange = useCallback(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setChange(false);
        navigate(clickedSidebarTab);
        dispatch(setClickedSidebarTab(null));
      });
    }
  }, [checkChanges, clickedSidebarTab, dispatch, navigate, setChange]);

  const onCloseUniqueSerialNumberModal = useCallback(() => {
    handleOnCloseModal();
    handleOnSave();
  }, [handleOnCloseModal, handleOnSave]);

  useEffect(() => {
    onClickedSidebarTabChange();
  }, [clickedSidebarTab]);

  const handleTreeOnSelect: DirectoryTreeProps['onSelect'] = useCallback(
    (keys: Key[]) => checkModeStatus(() => handleOnSelectedTreeNode(keys[0] as string)),
    [handleOnSelectedTreeNode, checkModeStatus]
  );

  const onEditOnlyContour = useCallback(
    (state: ISelectedSchemaEntity) => () => {
      setEditObject(state.object);
      closeConfirm();
      setMode({
        status: EVisualizerModes.edit,
        nodeType: state.type,
        editType: ETypesEdit.onlyShape,
        typeOfCreation: null,
        shape: null,
      });
      setChange(true);
    },
    [closeConfirm, setChange, setMode]
  );

  const onEditOnlyData = useCallback(
    (state: ISelectedSchemaEntity) => () => {
      setEditObject(state.object);
      closeConfirm();
      setChange(true);
      if (state.type) {
        treeNodeButtonsMap.get(state.type)?.onOpenModal();
      }
    },
    [closeConfirm, setChange, treeNodeButtonsMap]
  );

  const onEditAll = useCallback(
    (state: ISelectedSchemaEntity) => () => {
      setChange(true);
      setEditObject(state.object);
      closeConfirm();
      setMode({
        status: EVisualizerModes.edit,
        nodeType: state.type,
        typeOfCreation: null,
        shape: null,
        editType: ETypesEdit.all,
      });
    },
    [closeConfirm, setChange, setMode]
  );

  const onEditTreeNode = useCallback(
    (key: string) => {
      checkModeStatus(() => {
        const state = handleOnSelectedTreeNode(key);
        if (state.type !== TreeNodeType.accessPoint && state.type !== TreeNodeType.camera) {
          setConfirmData(editChoiceModal(onEditOnlyContour(state), onEditOnlyData(state), onEditAll(state)));
        } else if (state.type) {
          setEditObject(state.object);
          treeNodeButtonsMap.get(state.type)?.onOpenModal();
        }
      });
    },
    [
      checkModeStatus,
      handleOnSelectedTreeNode,
      setConfirmData,
      onEditOnlyContour,
      onEditOnlyData,
      onEditAll,
      treeNodeButtonsMap,
    ]
  );

  const deleteActiveObject = useCallback(
    (selected: ISelectedSchemaEntity) => {
      const { parent, nodeIndex, type } = selected;
      if (parent) {
        if (type === TreeNodeType.accessPoint && parent.accessPoints) {
          parent.accessPoints.splice(nodeIndex, 1);
        } else if (type === TreeNodeType.camera && parent.cameras) {
          parent.cameras.splice(nodeIndex, 1);
        } else if (parent.childItems) {
          parent.childItems.splice(nodeIndex, 1);
        }
      } else if (editableSchema.current) {
        editableSchema.current.splice(nodeIndex, 1);
      }
      setSchemas(editableSchema.current);
      setChange(true);
      setSelectedNode(initSelectObject);
      resetState();
      closeConfirm();
    },
    [closeConfirm, resetState, setChange, setSchemas, setSelectedNode]
  );

  const onDeleteTreeNode = useCallback(
    (selectedData: ISelectedSchemaEntity) =>
      checkModeStatus(() => {
        setConfirmData(
          deleteModal(
            selectedData.object ? selectedData.object.name || '' : '',
            () => deleteActiveObject(selectedData),
            () => {
              resetState();
              closeConfirm();
            }
          )
        );
      }),
    [checkModeStatus, closeConfirm, deleteActiveObject, resetState]
  );

  const tryToDeleteTreeNode = useCallback(
    (key: string) => {
      const selectedData = handleOnSelectedTreeNode(key);
      if (selectedData.type === TreeNodeType.camera && (selectedData?.object as any)?.camExternal) {
        setConfirmData({
          isOpen: true,
          description: `Чтобы удалить камеру ${selectedData.object?.name} с плана, необходимо ее отвязать от реального оборудования на вкладке "Настройки оборудования"`,
          buttons: [
            {
              label: 'Хорошо',
              type: ButtonType.primary,
              onClick: closeConfirm,
            },
          ],
        });
      } else if (
        selectedData.type === TreeNodeType.accessPoint &&
        (selectedData?.object as any)?.inputDevice.camExternal
      ) {
        setConfirmData({
          isOpen: true,
          description: `Чтобы удалить точку доступа с плана, необходимо отвязать оборудование ${selectedData.object?.name} от реального оборудования на вкладке "Настройки оборудования"`,
          buttons: [
            {
              label: 'Хорошо',
              type: ButtonType.primary,
              onClick: closeConfirm,
            },
          ],
        });
      } else {
        const devicesList: string[] = [];
        findNonRemovableDevices(selectedData.object as ITreeNode, devicesList);
        if (devicesList.length)
          setConfirmData({
            isOpen: true,
            description: `Чтобы удалить оборудование ${devicesList.map(
              (item) => `«${item}», `
            )} необходимо его  отвязать в «Настройках оборудования»`,
            buttons: [
              {
                label: 'Хорошо',
                type: ButtonType.primary,
                onClick: closeConfirm,
              },
            ],
          });
        else onDeleteTreeNode(selectedData);
      }
    },
    [closeConfirm, handleOnSelectedTreeNode, onDeleteTreeNode]
  );

  const openModal = useCallback(() => {
    if (currentPath.current && currentPath.current.closed && currentMode.nodeType) {
      treeNodeButtonsMap.get(currentMode.nodeType)?.onOpenModal();
    } else {
      Message.error({
        content: currentPath.current ? 'Фигура не замкнута' : 'Создайте замкнутую фигуру',
      });
    }
  }, [currentMode.nodeType, treeNodeButtonsMap]);

  const onSaveCreateMode = useCallback(() => {
    if (currentMode.editType === ETypesEdit.onlyShape && currentPath.current) {
      editActiveObject({ ...editObject, layout: getLayout(currentMode)(currentPath.current) } as ITreeNode);
    } else {
      openModal();
    }
  }, [currentMode, editActiveObject, editObject, openModal, currentPath]);

  const onCreateBackground = useCallback(
    (img: string) => {
      setBackground({ ...defaultBackground, img, layout: '' });
      setChange(true);
    },
    [setChange]
  );

  const onDeleteBackground = useCallback(() => {
    setBackground({ ...defaultBackground, img: '', layout: '' });
  }, []);

  const createModeButtons: IButtonData[] = useMemo(
    () => getCreateModeButtons(onSaveCreateMode, resetState),
    [onSaveCreateMode, resetState]
  );

  const onChangeOpacity = useCallback((value: ISchemaOpacity) => {
    setOpacityValues(value);
  }, []);

  const opacityButtons: IButtonData = useMemo(
    () => getOpacityButton(opacityValues, onChangeOpacity),
    [onChangeOpacity, opacityValues]
  );

  const onChangeBackgroundOpacity = useCallback(
    (val: number) => {
      setOpacityValues({ ...opacityValues, background: val });
    },
    [opacityValues]
  );

  const onShowMap = useCallback((show: boolean) => {
    setMapVisible(show);
  }, []);

  const findNode = useCallback(
    (nodes: ITreeNode[], type: ObjectMapItemType, id: string, key: string): IKeyNode | null => {
      for (let n = 0; n < nodes.length; n++) {
        const node = nodes[n];
        const nodeKey = getTreeKey(key, n, node.type || TreeNodeType.object);
        const collection = type === ObjectMapItemType.accessPoint ? node.accessPoints : node.cameras;
        if (collection?.length) {
          for (let p = 0; p < collection.length; p++) {
            const item = collection[p];
            if (item.id === id) {
              const itemKey = getTreeKey(nodeKey, p, item.type || TreeNodeType.camera);
              return { item: item, key: itemKey };
            }
          }
        }
        if (node.childItems?.length) {
          const res = findNode(node.childItems, type, id, nodeKey);
          if (res?.item?.id === id) {
            return { item: res.item, key: res.key };
          }
        }
      }
      return null;
    },
    []
  );

  const onMapItemClick = useCallback(
    (item: IMapItem) => {
      const keyNode = findNode(editableSchema.current, item.type, item.id, '');
      if (keyNode) {
        handleOnSelectedTreeNode(keyNode.key);
      }
    },
    [findNode, handleOnSelectedTreeNode]
  );

  const onMapItemMoved = useCallback(
    (item: IMapItem, coordinates: LngLat) => {
      const [lon, lat] = coordinates;
      const keyNode = findNode(editableSchema.current, item.type, item.id, '');
      if (keyNode?.item) {
        keyNode.item.coordinates = `${lat.toFixed(6)}, ${lon.toFixed(6)}`;
        haveBeenChanges();
      }
    },
    [findNode, haveBeenChanges]
  );

  const backgroundButtons: IButtonData[] = useMemo(
    () =>
      getBackgroundButtons(
        background,
        opacityValues.background,
        isMapVisible,
        () => setOpenModal(ESchemaModals.background),
        onDeleteBackground,
        onChangeBackgroundOpacity,
        onShowMap
      ),
    [background, isMapVisible, onChangeBackgroundOpacity, onDeleteBackground, onShowMap, opacityValues.background]
  );

  const getButtons = useCallback((): (IButtonData | null)[] => {
    if (currentMode.status === EVisualizerModes.default) {
      const activeType = selectedNode.type ? selectedNode.type : TreeNodeType.undefined;
      if (activeType === TreeNodeType.background) {
        currentButtons.current = backgroundButtons;
      } else {
        currentButtons.current =
          typeDependencies.get(activeType)?.map((type) => {
            let result = treeNodeButtonsMap.get(type) || null;
            if (
              selectedNode &&
              ((selectedNode.parent &&
                selectedNode.parent.type === TreeNodeType.restrictedArea &&
                selectedNode.object &&
                selectedNode.object.type === TreeNodeType.restrictedArea &&
                (type === TreeNodeType.restrictedArea || type === TreeNodeType.building)) ||
                (selectedNode.parent &&
                  selectedNode.parent.type === TreeNodeType.section &&
                  selectedNode.object &&
                  selectedNode.object.type === TreeNodeType.restrictedArea &&
                  (type === TreeNodeType.restrictedArea || type === TreeNodeType.building)) ||
                (selectedNode.parent &&
                  selectedNode.parent.type === TreeNodeType.building &&
                  selectedNode.object &&
                  selectedNode.object.type === TreeNodeType.restrictedArea &&
                  (type === TreeNodeType.building || type === TreeNodeType.restrictedArea)))
            ) {
              result = null;
            }

            return result;
          }) || [];
        currentButtons.current.push(opacityButtons);
      }
    } else {
      currentButtons.current = createModeButtons;
    }

    return currentButtons.current;
  }, [currentMode.status, selectedNode, backgroundButtons, opacityButtons, treeNodeButtonsMap, createModeButtons]);

  const checkboxes = useMemo(() => {
    let res: Array<CheckboxOptionType | string | number> = [];

    if (
      (selectedNode.type === TreeNodeType.building ||
        (selectedNode.type === TreeNodeType.restrictedArea && selectedNode.parents.length === 0)) &&
      currentMode.status === EVisualizerModes.default
    ) {
      res = [
        ...res,
        ...[
          { label: 'Отображать подъезды строения', value: TreeNodeType.section },
          { label: 'Отображать зоны доступа строения', value: TreeNodeType.restrictedArea },
        ],
      ];
    }
    if (
      selectedNode.type !== TreeNodeType.accessPoint &&
      selectedNode.type !== TreeNodeType.camera &&
      currentMode.status === EVisualizerModes.default
    ) {
      res = [...res, { label: 'Отображать точки доступа', value: TreeNodeType.accessPoint }];
      if (settings.camera) {
        res = [...res, { label: 'Отображать камеры', value: TreeNodeType.camera }];
      }
    }

    return res;
  }, [currentMode.status, selectedNode.parents.length, selectedNode.type, settings.camera]);

  const onResizeTab = useCallback(() => {
    if (fullScreenElementRef.current) {
      setFullScreen((flag) => {
        const newFlag = !flag;
        dispatch(setContentLayoutIsFullScreen(newFlag));

        return newFlag;
      });
    }
  }, [dispatch]);

  const handleNextTab = useCallback(() => {
    onNextTab();
    setFullScreen(false);
    dispatch(setContentLayoutIsFullScreen(false));
  }, [dispatch, onNextTab]);

  const getImg = useCallback(() => {
    const canvas = document.getElementById('schema-canvas');
    if (canvas) {
      canvas.style.width = '2000px';
      canvas.style.height = '2000px';
      canvas.style.opacity = '0';
    }
    setSelectedNode({ ...initSelectObject, originalKey: TreeNodeType.object });
    setSchemas([...editableSchema.current]);
    setTimeout(() => {
      setPdfModalOpen(true);
    }, 500);
  }, [setSchemas, setSelectedNode]);

  const onClosePdfModal = useCallback(() => {
    const canvas = document.getElementById('schema-canvas');
    if (canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.opacity = '1';
    }
    setSelectedNode({ ...initSelectObject, originalKey: TreeNodeType.object });
    setSchemas([...editableSchema.current]);
    setPdfModalOpen(false);
  }, [setSchemas, setSelectedNode]);

  const tryToGetImg = useCallback(() => {
    if (isWasChange) {
      setConfirmData({
        isOpen: true,
        description: 'Перед скачиванием плана необходимо его сохранить. Сохранить данные и скачать план в формате PDF?',
        buttons: [
          {
            label: 'Сохранить и скачать',
            type: ButtonType.primary,
            onClick: async () => {
              closeConfirm();
              if (await handleOnSave()) setTimeout(() => getImg(), 500);
            },
          },
          {
            label: 'Отмена',
            type: ButtonType.secondary,
            onClick: closeConfirm,
          },
        ],
      });
    } else {
      getImg();
    }
  }, [closeConfirm, getImg, handleOnSave, isWasChange]);

  const tabNavButtons = useMemo(
    () =>
      tabNavButtonsDefault(
        {
          callBack: handleOnCancel,
          disabled: currentMode.status !== EVisualizerModes.default || editPlanLoading || treeLoading,
          isHidden: !isWasChange || !permissions.edit,
        },
        {
          callBack: handleOnSave,
          disabled: currentMode.status !== EVisualizerModes.default || !isWasChange || treeLoading,
          isHidden: !permissions.edit,
          loading: editPlanLoading,
        },
        {
          callBack: handleNextTab,
          disabled:
            currentMode.status !== EVisualizerModes.default || !nextTabIsAvailable || editPlanLoading || treeLoading,
          isHidden: !permissions.edit,
        }
      ),
    [
      currentMode.status,
      permissions,
      editPlanLoading,
      handleNextTab,
      handleOnCancel,
      handleOnSave,
      isWasChange,
      nextTabIsAvailable,
      treeLoading,
    ]
  );

  return (
    <div
      className={classNames('system-schema-tab', {
        'system-schema-tab_fullscreen': isFullScreen,
      })}
      ref={fullScreenElementRef}
    >
      <Button
        className="system-schema-tab__download-button"
        rightIcon={<DownloadIcon />}
        type={ButtonType.outline}
        size={ButtonSize.small}
        onClick={tryToGetImg}
      >
        Скачать план
      </Button>
      <SchemaPDFModal
        settings={settings}
        system={system}
        isOpen={pfdModalOpen}
        onCancel={onClosePdfModal}
        localSchema={localSchema}
      />
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <BackgroundModal
        isOpen={openedModal === ESchemaModals.background}
        onCancel={handleOnCloseModal}
        onCreate={onCreateBackground}
      />
      <AccessZoneModal
        parent={selectedNode}
        isOpen={openedModal === ESchemaModals.accessZone}
        editObject={editObject as ITreeNode}
        onEdit={editActiveObject}
        onCancel={handleOnCloseModal}
        onCreate={createTreeNode}
        path={currentPath.current}
        getLayout={getLayout(currentMode)}
      />
      <BuildingsModal
        localSchema={localSchema}
        settings={settings}
        parent={selectedNode}
        isOpen={openedModal === ESchemaModals.building}
        editObject={editObject as ITreeNode}
        onEdit={editActiveObject}
        onCancel={handleOnCloseModal}
        onCreate={createTreeNode}
        path={currentPath.current}
        getLayout={getLayout(currentMode)}
      />
      <SectionModal
        parent={selectedNode}
        isOpen={openedModal === ESchemaModals.section}
        editObject={editObject as ITreeNode}
        onEdit={editActiveObject}
        onCancel={handleOnCloseModal}
        onCreate={createTreeNode}
        path={currentPath.current}
        getLayout={getLayout(currentMode)}
      />
      <AccessPointModal
        isFiveThousandth={isFiveThousandth}
        settings={settings}
        schema={editableSchema.current}
        parent={selectedNode}
        isOpen={openedModal === ESchemaModals.accessPoint}
        onEdit={editActiveObject}
        editObject={editObject as IAccessPoint}
        onCancel={handleOnCloseModal}
        onCreate={createTreePoint}
        getLayout={getPointLayout}
        objectBaseDeviceSeries={baseDeviceSeries}
      />
      <CameraModal
        schema={editableSchema.current}
        parent={selectedNode}
        isOpen={openedModal === ESchemaModals.camera}
        onEdit={editActiveObject}
        editObject={editObject as ICameraPoint}
        onCancel={handleOnCloseModal}
        onCreate={createCameraPoint}
        getLayout={getPointLayout}
      />
      <NonUniqueMacAddressModal
        isOpen={openedModal === ESchemaModals.nonUniqueMacAddress}
        data={macAddressData}
        onCancel={handleOnCloseModal}
        onOk={onCloseUniqueSerialNumberModal}
        editableSchema={editableSchema}
        setSchemas={setSchemas}
      />
      <NonUniqueSerialNumberModal
        isOpen={
          openedModal === ESchemaModals.nonUniqueSerialNumber || openedModal === ESchemaModals.errorsSerialNumbers
        }
        type={openedModal}
        serialNumbersData={serialNumbersData as INonUniqueSerialNumberData[]}
        onCancel={handleOnCloseModal}
        onOk={onCloseUniqueSerialNumberModal}
        editableSchema={editableSchema}
        setSchemas={setSchemas}
      />
      <div className="system-schema-tab__left-block">
        {tabId === activeTabKey && (
          <SchemaTree
            schema={localSchema}
            selectedKeys={selectedNode.originalKey ? [selectedNode.originalKey] : []}
            parents={selectedNode.parents}
            onSelect={handleTreeOnSelect}
            permissions={permissions}
            onEdit={onEditTreeNode}
            onDelete={tryToDeleteTreeNode}
            loading={treeLoading}
            settings={settings}
          />
        )}
      </div>
      <div className="system-schema-tab__right-block">
        <div className="system-schema-tab__schema-wrapper">
          {permissions.edit && (
            <SchemaButtons
              displayedObjects={displayedObjects}
              checkboxes={checkboxes}
              buttons={getButtons()}
              onChangeCheckbox={(e: CheckboxValueType[]) => setDisplayedObjects(e as TreeNodeType[])}
            />
          )}
          <div className="system-schema-tab__schema">
            {editPlanLoading || treeLoading ? (
              <div className="schema-canvas">
                <div className="system-item__loader-wrapper">
                  <Loader color={ELoaderColor.blue} />
                </div>
              </div>
            ) : (
              <>
                {activeTabKey === tabId && !isMapVisible && (
                  <SchemaCanvas
                    isFiveThousandth={isFiveThousandth}
                    onEdit={onEditTreeNode}
                    onDelete={tryToDeleteTreeNode}
                    opacityValues={opacityValues}
                    background={background}
                    permissions={permissions}
                    schema={editableSchema.current}
                    buttons={currentButtons.current}
                    onSelectNode={handleOnSelectedTreeNode}
                    selectedNode={selectedNode}
                    currentMode={currentMode}
                    setPath={setPath}
                    displayedObjects={displayedObjects}
                    haveBeenChanges={haveBeenChanges}
                    isFullScreen={isFullScreen}
                    onResizeTab={onResizeTab}
                    settings={settings}
                  />
                )}
                {activeTabKey === tabId && isMapVisible && (
                  <SchemaMap
                    objectId={systemId}
                    schema={editableSchema.current}
                    selectedNode={selectedNode}
                    displayedObjects={displayedObjects}
                    onItemClick={onMapItemClick}
                    onItemMoved={onMapItemMoved}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {isFullScreen && (
          <TabNavButtons containerClassName="system-schema-tab__tab-buttons-full-screen" buttons={tabNavButtons} />
        )}
        {!isFullScreen && <TabNavButtons buttons={tabNavButtons} />}
      </div>
    </div>
  );
};

export default SystemSchemaTab;
