import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { FC, SyntheticEvent, useMemo } from 'react';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import EditIcon from '../../assets/svg/icons/edit';
import { useAppSelector } from '../../hooks/hooks';
import { checkIsAdmin, checkIsDispatcher } from '../../store/selectors/profile';
import { ISystem } from '../../typings/systems/system';
import Loader from '../ui/loader';
import { ELoaderColor } from '../ui/loader/types';
import Tooltip from '../ui/tooltip';
import { ISystemTable } from './types';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import SearchFailIcon from '../../assets/svg/icons/searchFail';
import { dateFormatNoTime } from '../../constants/date';
import EmergencyIcon from '../../assets/svg/icons/emergency';

const SystemsTable: FC<ISystemTable> = (props) => {
  const {
    systems = [],
    onClickRow = () => {},
    onEditRow = () => {},
    onClickEmergency = () => {},
    pagination = false,
    permissions = {},
    loading = false,
  } = props;

  const isAdmin = useAppSelector(checkIsAdmin);
  const isDispatcher = useAppSelector(checkIsDispatcher);

  const deviceLinkClickHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const columns = useMemo<ColumnsType<ISystem>>(
    () =>
      [
        {
          title: 'Организация',
          dataIndex: 'companyName',
          key: 'companyName',
          hidden: !isAdmin,
          render: (_: any, record: ISystem) => <span role="presentation">{record.companyName}</span>,
        },
        {
          title: 'Краткое наименование',
          dataIndex: 'objectName',
          key: 'objectName',
          render: (_: any, record: ISystem) => (
            <div role="presentation" className="systems-table__text-bold">
              <span
                role="presentation"
                className={!record.isInEmergency ? 'systems-table__text-bold' : 'systems-table__text-bold emergency'}
              >
                {record.objectName}
                {record.isInEmergency && <span>Режим ЧС</span>}
              </span>
              {((record.devices?.length as number) > 0 || (record.cameras?.length as number) > 0) && (
                <div className="systems-table__found-items">
                  {(record.devices?.length as number) > 0 && <div>Найдены устройства:</div>}
                  {(record.devices?.length as number) > 0 &&
                    record.devices?.map((d) => (
                      <div key={d.id}>
                        <a
                          href={`/systems/${d.objectId}?tabId=BVConfiguration&originalKey=${d.id}_Device_0&itemId=${d.id}&itemType=Device`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={deviceLinkClickHandler}
                        >
                          {d.name}
                        </a>
                      </div>
                    ))}
                  {(record.cameras?.length as number) > 0 && <div>Найдены камеры:</div>}
                  {(record.cameras?.length as number) > 0 &&
                    record.cameras?.map((d) => (
                      <div key={d.id}>
                        <a
                          href={`/systems/${d.objectId}?tabId=BVConfiguration&originalKey=${d.id}_Camera_0&itemId=${d.id}&itemType=Camera`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={deviceLinkClickHandler}
                        >
                          {d.name}
                        </a>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ),
        },
        {
          title: 'Строений',
          dataIndex: 'buildingsCount',
          key: 'buildingsCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.buildingsCount}</span>,
        },
        {
          title: 'Подъездов',
          dataIndex: 'sectionsCount',
          key: 'sectionsCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.sectionsCount}</span>,
        },
        {
          title: 'Точки доступа',
          dataIndex: 'accessPointCount',
          key: 'accessPointCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.accessPointCount}</span>,
        },
        {
          title: 'Камеры',
          dataIndex: 'externalCamerasCount',
          key: 'externalCamerasCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.externalCamerasCount}</span>,
        },
        {
          title: 'Адрес',
          dataIndex: 'address',
          key: 'address',
          render: (_: any, record: ISystem) => <span role="presentation">{record.address}</span>,
        },
        {
          title: 'Создан',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (_: any, record: ISystem) => (
            <span role="presentation">{record.createdAt ? moment(record.createdAt).format(dateFormatNoTime) : ''}</span>
          ),
        },
        {
          className: 'table__icon-cell',
          hidden: !permissions.edit && !permissions.delete,
          render: (_: any, record: ISystem) => (
            <div role="presentation" className="table__icon-container">
              {permissions.edit && (
                <Tooltip title="Редактировать" placement="bottom">
                  <div
                    onClick={() => onEditRow(record.id || '', record)}
                    role="presentation"
                    className="systems-table__icon"
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
              )}
              {isDispatcher && (
                <Tooltip
                  title={!record.isInEmergency ? 'Перевести объект в режим ЧС' : 'Отменить режим ЧС на объекте'}
                  placement="bottom"
                >
                  <div onClick={(e) => onClickEmergency(record, e)} role="presentation" className="systems-table__icon">
                    <EmergencyIcon />
                  </div>
                </Tooltip>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [isAdmin, onEditRow, permissions.delete, permissions.edit]
  );

  const data = useMemo(
    () =>
      systems.map((system) => ({
        ...system,
        key: system.id,
      })),
    [systems]
  );

  return (
    <div className="systems-table table">
      <Table
        columns={columns}
        dataSource={data.reverse()}
        showSorterTooltip={false}
        locale={{
          emptyText: (
            <ErrorPlaceholder
              containerClassName="device-event-table__error-wrapper"
              text="По вашему запросу ничего не найдено"
              icon={<SearchFailIcon />}
            />
          ),
        }}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id || '', record),
        })}
        pagination={
          pagination && {
            current: pagination.currentPage + 1,
            pageSize: pagination.pageSize,
            hideOnSinglePage: true,
            total: pagination.total,
            onChange: pagination.onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'table__pagination table__pagination_absolute',
          }
        }
      />
    </div>
  );
};

export default SystemsTable;
