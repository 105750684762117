import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getSystem } from '../../../../api/systems';
import { getDeviceCategoriesUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { ESystemBaseDeviceSeries } from '../../../../typings/systems/base';
import {
  AccessPointType,
  IAccessPoint,
  IAccessPointDeviceCategory,
  IRelatedAccessPoint,
  ITreeNode,
  TreeNodeType,
} from '../../../../typings/treeNode';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Checkbox from '../../../ui/checkbox';
import Input from '../../../ui/input';
import { IInputValue, InputStatus, defaultNotRequiredValue } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import NotificationModal from '../../../ui/notificationModal';
import RadioGroup from '../../../ui/radioGroup';
import { ERadioGroupDirection, IRadioGroupOption } from '../../../ui/radioGroup/types';
import Tooltip from '../../../ui/tooltip';
import { IAccessPointModal } from './types';
import { deviceNameLength } from '../../../../constants/limit';
import { TreeNodeNamesMap } from '../constans';
import SimpleTabs from '../../../ui/simpleTabs';
import SelectMultiple from '../../../ui/selectMultiple';
import { ISimpleTab } from '../../../ui/simpleTabs/types';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm } from '../../../ui/universalModal/config';
import UniversalModal from '../../../ui/universalModal';
import SerialNumberInput from '../../../ui/input/serialNumberInput';
import { checkIsCallBlock } from '../calculations';
import { pointIconsMap } from '../templates';
import CoordinateInput from '../../../ui/input/maskInput/coordinateInput';

const deviceInputNameCorrection = '_вход';
const deviceOutputNameCorrection = '_выход';

const AccessPointModal: FC<IAccessPointModal> = (props) => {
  const {
    schema,
    isOpen = false,
    parent = null,
    onCreate = () => {},
    onCancel = () => {},
    editObject,
    onEdit = () => {},
    getLayout = () => '',
    objectBaseDeviceSeries = ESystemBaseDeviceSeries.D7000,
    settings,
    isFiveThousandth,
  } = props;

  const { data: deviceCategories, sendRequest: requestDeviceCategories } =
    useApi<IAccessPointDeviceCategory[]>(getSystem);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [deviceCategoryModalConfirm, setDeviceCategoryModalConfirm] = useState<{
    value?: string;
    valueSetter?: React.Dispatch<React.SetStateAction<IAccessPointDeviceCategory | null>>;
    isOpen: boolean;
  }>({ isOpen: false });

  const [isNotCustomOutputDevice, setIsNotCustomOutputDevice] = useState<boolean>(true);
  const [name, setName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [shortName, setShortName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [deviceType, setDeviceType] = useState<AccessPointType>(AccessPointType.door);
  const [coordinates, setCoordinates] = useState<IInputValue>({ ...defaultNotRequiredValue });

  const [inputDeviceCategory, setInputDeviceCategory] = useState<IAccessPointDeviceCategory | null>(null);
  const [inputDeviceName, setInputDeviceName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [inputDeviceSerialNumber, setInputDeviceSerialNumber] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [inputDeviceNetworkAddress, setInputDeviceNetworkAddress] = useState<IInputValue>({
    ...defaultNotRequiredValue,
  });

  const [outputDeviceCategory, setOutputDeviceCategory] = useState<IAccessPointDeviceCategory | null>(null);
  const [outputDeviceName, setOutputDeviceName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [outputDeviceSerialNumber, setOutputDeviceSerialNumber] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [outputDeviceNetworkAddress, setOutputDeviceNetworkAddress] = useState<IInputValue>({
    ...defaultNotRequiredValue,
  });

  const [relatedPoints, setRelatedPoints] = useState<string[]>([]);

  const isWicket = useMemo(() => deviceType === AccessPointType.wicket, [deviceType]);

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) =>
      setter({ ...defaultNotRequiredValue, value }),
    []
  );

  const handleOnChangeAccessPointName = useCallback((value: string) => {
    setName({ ...defaultNotRequiredValue, value });
    setInputDeviceName({
      ...defaultNotRequiredValue,
      value: value ? `${value.trim()}${deviceInputNameCorrection}` : '',
    });
    setOutputDeviceName({
      ...defaultNotRequiredValue,
      value: value ? `${value.trim()}${deviceOutputNameCorrection}` : '',
    });
  }, []);

  const handleOnChangeCheckbox = useCallback(
    (value: boolean) => {
      setIsNotCustomOutputDevice(value);
      setOutputDeviceName({
        ...defaultNotRequiredValue,
        value: name.value ? `${name.value.trim()}${deviceOutputNameCorrection}` : '',
      });
    },
    [name.value]
  );

  const findAllAccessPoint = useCallback(
    (node: ITreeNode, pointsList: IAccessPoint[]) => {
      node.accessPoints?.forEach((item) => {
        if (checkIsCallBlock(item) && editObject?.id !== item.id) {
          pointsList.push(item);
        }
      });
      node.childItems?.forEach((item) => findAllAccessPoint(item, pointsList));
    },
    [editObject?.id]
  );

  const allPoints = useMemo(() => {
    const res: IAccessPoint[] = [];
    schema?.forEach((item) => findAllAccessPoint(item, res));
    return res;
  }, [findAllAccessPoint, schema]);

  const validateInputs = useCallback(() => {
    let isValid = true;

    if (!name.value || !name.value.trim()) {
      setName({ ...name, status: InputStatus.error });
      isValid = false;
    }

    if (!shortName.value || !shortName.value.trim()) {
      setShortName({ ...shortName, status: InputStatus.error });
      isValid = false;
    }

    if (!inputDeviceName.value || !inputDeviceName.value.trim()) {
      setInputDeviceName({ ...inputDeviceName, status: InputStatus.error });
      isValid = false;
    }

    if (!isNotCustomOutputDevice && !outputDeviceName.value) {
      setOutputDeviceName({ ...outputDeviceName, status: InputStatus.error });
      isValid = false;
    }

    return isValid;
  }, [inputDeviceName, isNotCustomOutputDevice, name, outputDeviceName, shortName]);

  const getRelatedPoints = useCallback(
    (): IRelatedAccessPoint[] =>
      relatedPoints.map((item) => {
        const oldPoint = editObject?.relatedAccessPoints?.find((point) => point.accessPointId === item);
        if (oldPoint) return oldPoint;

        const newPoint = allPoints.find((point) => point.id === item);
        return {
          accessPointId: item,
          shortName: newPoint?.shortName || '',
          isAutoRelation: false,
        };
      }),
    [allPoints, editObject?.relatedAccessPoints, relatedPoints]
  );

  const handleOnClickSubmitButton = useCallback(() => {
    if (validateInputs()) {
      const isNew = !!(editObject ? editObject.isNew : true);

      const newAccessPoint: IAccessPoint = {
        isNew,
        id: editObject?.id || uuidv4(),
        name: name.value,
        relatedAccessPoints: deviceType === AccessPointType.door ? [] : getRelatedPoints(),
        shortName: shortName.value,
        coordinates: coordinates.value,
        deviceType,
        inputDevice: {
          id: editObject?.inputDevice?.id || uuidv4(),
          isNew: !!(editObject?.inputDevice ? editObject?.inputDevice?.isNew : true),
          name: inputDeviceName.value,
          isInput: true,
          isOutput: false,
          serialNumber: inputDeviceSerialNumber.value,
          networkAddress: inputDeviceNetworkAddress.value,
          deviceCategory: inputDeviceCategory || undefined,
        },
        type: TreeNodeType.accessPoint,
        outputDevice: isNotCustomOutputDevice
          ? null
          : {
              id: editObject?.outputDevice?.id || uuidv4(),
              name: outputDeviceName.value,
              isNew: !!(editObject?.outputDevice ? editObject?.outputDevice?.isNew : true),
              isInput: false,
              isOutput: true,
              serialNumber: outputDeviceSerialNumber.value,
              networkAddress: outputDeviceNetworkAddress.value,
              deviceCategory: outputDeviceCategory || undefined,
            },
      };
      newAccessPoint.layout = getLayout(newAccessPoint, editObject?.layout || null);

      if (editObject) {
        onEdit(newAccessPoint);
      } else {
        onCreate(newAccessPoint);
      }

      onCancel();
    }
  }, [
    validateInputs,
    editObject,
    name.value,
    deviceType,
    getRelatedPoints,
    shortName.value,
    coordinates.value,
    inputDeviceName.value,
    inputDeviceSerialNumber.value,
    inputDeviceNetworkAddress.value,
    inputDeviceCategory,
    isNotCustomOutputDevice,
    outputDeviceName.value,
    outputDeviceSerialNumber.value,
    outputDeviceNetworkAddress.value,
    outputDeviceCategory,
    getLayout,
    onCancel,
    onEdit,
    onCreate,
  ]);

  useEffect(() => {
    if (isOpen) {
      requestDeviceCategories(getDeviceCategoriesUrl());
    }

    if (!editObject) {
      setIsNotCustomOutputDevice(true);
      setName({ ...defaultNotRequiredValue });
      setShortName({ ...defaultNotRequiredValue });
      setDeviceType(AccessPointType.door);
      setCoordinates({ ...defaultNotRequiredValue });
      setRelatedPoints([]);

      setInputDeviceCategory(null);
      setInputDeviceName({ ...defaultNotRequiredValue });
      setInputDeviceSerialNumber({ ...defaultNotRequiredValue });
      setInputDeviceNetworkAddress({ ...defaultNotRequiredValue });

      setOutputDeviceCategory(null);
      setOutputDeviceName({ ...defaultNotRequiredValue });
      setOutputDeviceSerialNumber({ ...defaultNotRequiredValue });
      setOutputDeviceNetworkAddress({ ...defaultNotRequiredValue });
    }
  }, [editObject, isOpen, requestDeviceCategories]);

  useEffect(() => {
    if (editObject) {
      setIsNotCustomOutputDevice(!editObject.outputDevice);
      setName({ ...defaultNotRequiredValue, value: editObject.name || '' });
      setShortName({ ...defaultNotRequiredValue, value: editObject.shortName || '' });
      setDeviceType(editObject.deviceType || AccessPointType.door);
      setCoordinates({ ...defaultNotRequiredValue, value: editObject.coordinates || '' });

      setRelatedPoints(editObject.relatedAccessPoints?.map((item) => item.accessPointId) || []);

      setInputDeviceCategory(editObject.inputDevice?.deviceCategory || null);
      setInputDeviceName({ ...defaultNotRequiredValue, value: editObject.inputDevice?.name || '' });
      setInputDeviceSerialNumber({ ...defaultNotRequiredValue, value: editObject.inputDevice?.serialNumber || '' });
      setInputDeviceNetworkAddress({ ...defaultNotRequiredValue, value: editObject.inputDevice?.networkAddress || '' });

      setOutputDeviceCategory(editObject.outputDevice?.deviceCategory || null);
      setOutputDeviceName({ ...defaultNotRequiredValue, value: editObject.outputDevice?.name || '' });
      setOutputDeviceSerialNumber({ ...defaultNotRequiredValue, value: editObject.outputDevice?.serialNumber || '' });
      setOutputDeviceNetworkAddress({
        ...defaultNotRequiredValue,
        value: editObject.outputDevice?.networkAddress || '',
      });
    }
  }, [editObject]);

  useEffect(() => {
    if (deviceCategories && deviceCategories.length) {
      if (!inputDeviceCategory) {
        setInputDeviceCategory(deviceCategories[0]);
      }
      if (!outputDeviceCategory) {
        setOutputDeviceCategory(deviceCategories[0]);
      }
    }
  }, [deviceCategories]);

  const onDeleteConnection = useCallback(
    (id: string) => {
      setRelatedPoints(relatedPoints.filter((item) => item !== id));
    },
    [relatedPoints]
  );

  const renderIcons = useCallback(() => {
    const result: JSX.Element[] = [];
    pointIconsMap.forEach((value, key) => {
      if (isFiveThousandth ? true : editObject?.deviceType === key)
        result.push(
          <Tooltip key={key} title={value.description} placement="bottom">
            <div
              role="presentation"
              onClick={() => setDeviceType(key)}
              className={classNames(
                'access-point-modal__icons-item',
                deviceType === key ? 'access-point-modal__icons-item_active' : ''
              )}
            >
              {value.element}
            </div>
          </Tooltip>
        );
    });
    return result;
  }, [deviceType, editObject?.deviceType, isFiveThousandth]);

  const changeCategoryDevice = useCallback(
    (deviceCategoryId: string, setter: React.Dispatch<React.SetStateAction<IAccessPointDeviceCategory | null>>) => {
      setter(deviceCategories?.find((item) => item.id === deviceCategoryId) || null);
    },
    [deviceCategories]
  );

  const handleOnChangeCategoryDevice = useCallback(
    (
      deviceCategoryId: string,
      setter: React.Dispatch<React.SetStateAction<IAccessPointDeviceCategory | null>>,
      device: 'input' | 'output'
    ) => {
      if (
        editObject &&
        ((device === 'input' && editObject.inputDevice?.selectedDeviceTypeId) ||
          (device === 'output' && editObject.outputDevice?.selectedDeviceTypeId))
      ) {
        setDeviceCategoryModalConfirm({ value: deviceCategoryId, valueSetter: setter, isOpen: true });
      } else {
        changeCategoryDevice(deviceCategoryId, setter);
      }
    },
    [changeCategoryDevice, editObject]
  );

  const handleOnOkDeviceCategoryModal = useCallback(() => {
    if (deviceCategoryModalConfirm.valueSetter && deviceCategoryModalConfirm.value) {
      changeCategoryDevice(deviceCategoryModalConfirm.value, deviceCategoryModalConfirm.valueSetter);
    }
    setDeviceCategoryModalConfirm({ isOpen: false });
  }, [changeCategoryDevice, deviceCategoryModalConfirm.value, deviceCategoryModalConfirm.valueSetter]);

  const handleOnCancelDeviceCategoryModal = useCallback(() => {
    setDeviceCategoryModalConfirm({ isOpen: false });
  }, []);

  const deviceCategoriesOptions = useMemo(
    () =>
      deviceCategories?.map<IRadioGroupOption>((category) => ({
        title: category.displayName || '',
        value: category.id,
      })) || [],
    [deviceCategories]
  );

  const pointsOptions = useMemo(
    () =>
      allPoints.map((item) => ({
        value: item.id || '',
        title: item.shortName || '',
        disabled: !!editObject?.relatedAccessPoints?.find((point) => point.accessPointId === item.id)?.isAutoRelation,
      })),
    [allPoints, editObject?.relatedAccessPoints]
  );

  const pointsTabs = useMemo(
    (): ISimpleTab[] =>
      relatedPoints.map((item) => ({
        id: item,
        name: allPoints.find((elem) => elem.id === item)?.shortName || '',
        disabled: !!editObject?.relatedAccessPoints?.find((point) => point.accessPointId === item)?.isAutoRelation,
      })),
    [allPoints, editObject?.relatedAccessPoints, relatedPoints]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={editObject ? 'Редактирование точки доступа' : 'Создание точки доступа'}
      onCancel={onCancel}
      wrapClassName="schema-modal__wrapper"
      width={960}
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={handleOnClickSubmitButton} size={ButtonSize.small}>
            {editObject ? 'Редактировать' : 'Создать'}
          </Button>
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <NotificationModal
        isOpen={deviceCategoryModalConfirm.isOpen}
        title="При изменении категории оборудования указанные настройки оборудования будут удалены. Вы хотите внести изменение?"
        onOk={handleOnOkDeviceCategoryModal}
        onCancel={handleOnCancelDeviceCategoryModal}
        onClose={handleOnCancelDeviceCategoryModal}
        okButtonText="Да"
        cancelButtonText="Нет"
      />
      <div className="schema-modal default-scrollbar-override access-point-modal">
        {!editObject && parent?.type && TreeNodeNamesMap.get(parent.type) && (
          <div className="schema-modal__parent">
            {TreeNodeNamesMap.get(parent.type)}. {parent.object?.name}
          </div>
        )}
        <div className="access-point-modal__content-container">
          <Input
            title="Название точки доступа"
            maxLength={94}
            value={name.value}
            status={name.status}
            onChange={handleOnChangeAccessPointName}
            placeholder="Название точки доступа"
            isRequired
            errorText={name.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
          />
          {isFiveThousandth || editObject ? (
            <div className="access-point-modal__base-info-container">
              <Input
                title="Сокращенное название"
                isRequired
                maxLength={5}
                textInfo="Для отображения на плане"
                value={shortName.value}
                status={shortName.status}
                onChange={(value) => handleOnChangeInput(setShortName, value)}
                placeholder="Сокращенное название точки доступа"
                errorText={shortName.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
              />
              <div className="access-point-modal__icons">
                <div className="access-point-modal__icons-title">Тип точки доступа</div>
                <div className="access-point-modal__icons-container">{renderIcons()}</div>
              </div>
            </div>
          ) : (
            <Input
              title="Сокращенное название"
              isRequired
              maxLength={5}
              textInfo="Для отображения на плане"
              value={shortName.value}
              status={shortName.status}
              onChange={(value) => handleOnChangeInput(setShortName, value)}
              placeholder="Сокращенное название точки доступа"
              errorText={shortName.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
            />
          )}
          <CoordinateInput
            title="Координаты WGS 84"
            placeholder="Координаты WGS 84"
            value={coordinates.value}
            onChange={(value) => handleOnChangeInput(setCoordinates, value)}
            status={coordinates.status}
            errorText={coordinates.errorText}
            titleClassName="input__title-container"
          />
          <div className="access-point-modal__checkbox-wrapper">
            <Checkbox
              disabled={!!editObject}
              checked={isNotCustomOutputDevice}
              onChange={handleOnChangeCheckbox}
              label="Выход по кнопке"
            />
          </div>
        </div>
        <div className="access-point-modal__content-container">
          <div className="access-point-modal__device-setting-container">
            <span className="access-point-modal__device-setting-title">Вход</span>
            <div className="access-point-modal__device-radio-group-container">
              <RadioGroup
                disabled={!!editObject}
                title="Категория оборудования"
                options={deviceCategoriesOptions}
                value={inputDeviceCategory?.id || ''}
                onChange={(value) => handleOnChangeCategoryDevice(value, setInputDeviceCategory, 'input')}
                itemClassName="access-point-modal__radio-item"
                direction={ERadioGroupDirection.horizontal}
              />
            </div>
            <Input
              title="Название оборудования"
              textInfo="Это название будет использоваться в мобильном приложении"
              isRequired
              maxLength={deviceNameLength}
              value={inputDeviceName.value}
              status={inputDeviceName.status}
              onChange={(value) => handleOnChangeInput(setInputDeviceName, value)}
              placeholder="Название оборудования"
              errorText={inputDeviceName.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
            />
            <SerialNumberInput
              title="Серийный номер"
              maxLength={20}
              value={inputDeviceSerialNumber.value}
              status={inputDeviceSerialNumber.status}
              onChange={(value) => handleOnChangeInput(setInputDeviceSerialNumber, value)}
              placeholder="Серийный номер"
            />
            {/* {objectBaseDeviceSeries !== ESystemBaseDeviceSeries.D7000 && (
              <Input
                title="Сетевой адрес"
                maxLength={20}
                value={inputDeviceNetworkAddress.value}
                status={inputDeviceNetworkAddress.status}
                onChange={(value) => handleOnChangeInput(setInputDeviceNetworkAddress, value)}
                placeholder="Сетевой адрес"
              />
            )} */}
          </div>
          <div
            className={classNames('access-point-modal__device-setting-container', {
              'access-point-modal__device-setting-container_disabled': isNotCustomOutputDevice,
            })}
          >
            <span className="access-point-modal__device-setting-title">Выход</span>
            <div className="access-point-modal__device-radio-group-container">
              <RadioGroup
                disabled={!!editObject}
                title="Категория оборудования"
                options={deviceCategoriesOptions}
                value={outputDeviceCategory?.id || ''}
                onChange={(value) => handleOnChangeCategoryDevice(value, setOutputDeviceCategory, 'output')}
                itemClassName="access-point-modal__radio-item"
                direction={ERadioGroupDirection.horizontal}
              />
            </div>
            <Input
              title="Название оборудования"
              textInfo="Это название будет использоваться в мобильном приложении"
              isRequired
              maxLength={deviceNameLength}
              value={outputDeviceName.value}
              status={outputDeviceName.status}
              onChange={(value) => handleOnChangeInput(setOutputDeviceName, value)}
              placeholder="Название оборудования"
              disabled={isNotCustomOutputDevice}
              errorText={outputDeviceName.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
            />
            <SerialNumberInput
              title="Серийный номер"
              maxLength={20}
              value={outputDeviceSerialNumber.value}
              status={outputDeviceSerialNumber.status}
              onChange={(value) => handleOnChangeInput(setOutputDeviceSerialNumber, value)}
              placeholder="Серийный номер"
              disabled={isNotCustomOutputDevice}
            />
            {/* {objectBaseDeviceSeries !== ESystemBaseDeviceSeries.D7000 && (
              <Input
                title="Сетевой адрес"
                maxLength={20}
                value={outputDeviceNetworkAddress.value}
                status={outputDeviceNetworkAddress.status}
                onChange={(value) => handleOnChangeInput(setOutputDeviceNetworkAddress, value)}
                placeholder="Сетевой адрес"
                disabled={isNotCustomOutputDevice}
              />
            )} */}
          </div>
        </div>
        {!isFiveThousandth && isWicket && !!editObject && settings?.wicket && (
          <div className="access-point-modal__connects">
            <span className="access-point-modal__device-setting-title">Связанные точки доступа</span>
            <SelectMultiple
              values={relatedPoints}
              options={pointsOptions}
              onChange={setRelatedPoints}
              showSearch
              notAllowAllChoice
            />
            <SimpleTabs scrollView={false} tabs={pointsTabs} onDelete={onDeleteConnection} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AccessPointModal;
