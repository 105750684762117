import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { InputStatus } from '../../../ui/input/types';
import { useApi } from '../../../../hooks/useApi';
import { postRequest, putRequest } from '../../../../api';
import { IEmergencyReasonModalProps } from './types';
import { createEmergencyReasonUrl, updateEmergencyReasonUrl } from '../../../../constants/api';
import { IErrorResponseData } from '../../../../typings/api';

const EmergencyReasonModal: FC<IEmergencyReasonModalProps> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, editItem = null } = props;

  const { sendRequest: createReason, loading: isCreating } = useApi(postRequest);
  const { sendRequest: updateReason, loading: isUpdating } = useApi(putRequest);

  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (editItem && isOpen) {
      setName(editItem.name);
    } else {
      setName('');
      setErrorMessage('');
    }
  }, [editItem, isOpen]);

  const onSave = useCallback(async () => {
    const request = { id: editItem?.id, name };
    const err = !editItem?.id
      ? ((await createReason(createEmergencyReasonUrl(), request)) as AxiosError<IErrorResponseData>)
      : ((await updateReason(updateEmergencyReasonUrl(), request)) as AxiosError<IErrorResponseData>);
    if (!err?.response?.data) {
      onOk();
    } else {
      setErrorMessage(err?.response?.data.message);
    }
  }, [createReason, editItem?.id, name, onOk, updateReason]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={!editItem ? 'Создание новой причины ЧС' : 'Редактирование причины ЧС'}
      width={410}
      showCloseIcon
      wrapClassName="emergency-reason-modal__wrapper"
      centered
      footer={
        <div className="emergency-reason-modal__button-container">
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onCancel}
            disabled={isCreating || isUpdating}
          >
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onSave} disabled={!name?.trim() || isCreating || isUpdating}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Input
        title="Название"
        isRequired
        errorText={errorMessage}
        value={name}
        onChange={setName}
        status={errorMessage ? InputStatus.error : InputStatus.normal}
        placeholder="Название причины ЧС"
        maxLength={40}
      />
    </Modal>
  );
};

export default EmergencyReasonModal;
