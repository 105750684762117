import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LngLat, YMap as YMapType, YMapLocationRequest } from '@yandex/ymaps3-types';
import type { Feature } from '@yandex/ymaps3-clusterer';
import {
  YMap,
  YMapClusterer,
  YMapControls,
  YMapDefaultFeaturesLayer,
  YMapDefaultSchemeLayer,
  YMapFeatureDataSource,
  YMapLayer,
  YMapZoomControl,
  clusterByGrid,
  YMapListener,
} from '../yandexMap';
import { defaultLocation } from '../yandexMap/const';
import { getRequest } from '../../api';
import { useApi } from '../../hooks/useApi';
import { IMapItem, IMapItemCreateResult, IObjectAddress, ISchemaMap, ObjectMapItemType } from './types';
import { getObjectAddressUrl } from '../../constants/api';
import MapItem from './mapItem';
import { AccessPointType, IAccessPoint, ICameraPoint, ITreeNode } from '../../typings/treeNode';
import { parseCoordinates } from '../../utils/common';
import ClusterComponent from '../yandexMap/cluster';

const gridCluster: any = clusterByGrid;
const defaultZoom = 20;
const defaultCoordOffset = -0.00015;
const defaultCoordStep = 0.00005;
const clusterTolerance = 64;

const SchemaMap: FC<ISchemaMap> = (props) => {
  const { objectId, schema, selectedNode, displayedObjects, onItemClick, onItemMoved } = props;

  const { data: objectAddress, sendRequest: getObjectAddress } = useApi<IObjectAddress>(getRequest);

  const [location, setLocation] = useState<YMapLocationRequest>(defaultLocation);

  const [objectLocationLoaded, setObjectLocationLoaded] = useState(false);

  const yMap = useRef<YMapType | null>(null);

  const gridSizedMethod = useMemo(() => gridCluster({ gridSize: clusterTolerance }), []);

  const createItem = useCallback(
    (
      node: IAccessPoint | ICameraPoint,
      type: ObjectMapItemType,
      index: number,
      deviceType?: AccessPointType
    ): IMapItemCreateResult => {
      let defaultCoordinates = false;
      let [lat, lon] = parseCoordinates(node.coordinates);
      if (!lat || !lon) {
        const offset = defaultCoordOffset + index * defaultCoordStep;
        lat = objectAddress?.latitude || null;
        if (lat) lat += offset;
        lon = objectAddress?.longitude || null;
        if (lon) lon += offset;
        defaultCoordinates = true;
      }
      const newItem: IMapItem = {
        id: node.id || '',
        type: type,
        deviceType: deviceType,
        name: node.name || '',
        latitude: lat,
        longitude: lon,
      };
      return { item: newItem, defaultCoordinates: defaultCoordinates, nextItemIndex: index + 1 };
    },
    [objectAddress?.latitude, objectAddress?.longitude]
  );

  const synchronizeMapItems = useCallback(
    (nodes: ITreeNode[], items: IMapItem[], index: number): number => {
      nodes.forEach((node) => {
        if (node.accessPoints?.length) {
          node.accessPoints.forEach((accPt) => {
            const newItem = createItem(accPt, ObjectMapItemType.accessPoint, index, accPt.deviceType);
            items.push(newItem.item);
            index = newItem.nextItemIndex;
            if (newItem.defaultCoordinates) {
              onItemMoved(newItem.item, [newItem.item.longitude || 0, newItem.item.latitude || 0]);
            }
          });
        }
        if (node.cameras?.length) {
          node.cameras.forEach((cam) => {
            const newItem = createItem(cam, ObjectMapItemType.camera, index);
            index = newItem.nextItemIndex;
            items.push(newItem.item);
            if (newItem.defaultCoordinates) {
              onItemMoved(newItem.item, [newItem.item.longitude || 0, newItem.item.latitude || 0]);
            }
          });
        }
        if (node.childItems?.length) {
          index = synchronizeMapItems(node.childItems, items, index);
        }
      });
      return index;
    },
    [createItem, onItemMoved]
  );

  const mapItems = useMemo(() => {
    const result: IMapItem[] = [];
    if (objectLocationLoaded) {
      synchronizeMapItems(schema, result, 0);
    }
    return result;
  }, [objectLocationLoaded, schema, synchronizeMapItems]);

  const placeMarks = useMemo(
    (): Feature[] =>
      mapItems
        .filter((item: IMapItem) => item.latitude && item.longitude)
        .map((item: IMapItem) => ({
          type: 'Feature',
          id: item.id,
          geometry: { type: 'Point', coordinates: [item.longitude || 0, item.latitude || 0] },
        })) || [],
    [mapItems]
  );

  const handleItemMoved = useCallback(
    (item: IMapItem, coordinates: LngLat) => {
      const mapItem = mapItems.find((i: IMapItem) => i.id === item.id);
      if (mapItem) {
        const [lon, lat] = coordinates;
        mapItem.latitude = lat;
        mapItem.longitude = lon;
      }
      if (onItemMoved) onItemMoved(item, coordinates);
    },
    [onItemMoved, mapItems]
  );

  const renderMarker = useCallback(
    (feature: Feature) => {
      const item = mapItems.find((i: IMapItem) => feature.id === i.id && displayedObjects?.includes(i.type));
      const isSelected = item?.id === selectedNode?.object?.id;
      return item ? (
        <MapItem item={item} isSelected={isSelected} onClick={onItemClick} onMoved={handleItemMoved} />
      ) : (
        <div />
      );
    },
    [mapItems, selectedNode?.object?.id, onItemClick, handleItemMoved, displayedObjects]
  );

  const updateLocation = useCallback((newLocation: YMapLocationRequest) => {
    setLocation(newLocation);
  }, []);

  const updateHandler = useCallback(
    (e: any) => {
      updateLocation({
        center: e.location.center,
        zoom: e.location.zoom,
      });
    },
    [updateLocation]
  );

  const onClickCluster = useCallback(
    (coordinates: LngLat) => () => {
      if (yMap.current) {
        updateLocation({ center: coordinates, zoom: yMap.current.zoom + 3 });
      }
    },
    [updateLocation]
  );

  const renderCluster = useCallback(
    (coordinates: LngLat, features: Feature[]) => (
      <ClusterComponent coordinates={coordinates} features={features} onClick={onClickCluster} />
    ),
    [onClickCluster]
  );

  useEffect(() => {
    if (!objectId) return;
    getObjectAddress(getObjectAddressUrl(objectId)).then((data) => {
      const objMap = data as IObjectAddress;
      const objLocation = {
        center: [objMap.longitude, objMap.latitude],
        zoom: defaultZoom,
      };
      setLocation(objLocation);
      setObjectLocationLoaded(true);
    });
  }, [getObjectAddress, objectId]);

  useEffect(() => {
    if (!selectedNode) return;
    const item = mapItems.find((i) => i.id === selectedNode?.object?.id);
    if (!item?.latitude || !item?.longitude) return;
    updateLocation({ center: [item.longitude, item.latitude], zoom: defaultZoom });
  }, [mapItems, selectedNode, updateLocation]);

  return (
    <div className="schema-map">
      <YMap zoomRange={{ max: 25, min: 0 }} ref={(x) => (yMap.current = x)} location={location}>
        <YMapDefaultSchemeLayer />
        <YMapDefaultFeaturesLayer />
        <YMapFeatureDataSource id="clusterer-source" />
        <YMapLayer source="clusterer-source" type="markers" />
        <YMapControls position="right bottom" orientation="vertical">
          <YMapZoomControl />
        </YMapControls>
        <YMapClusterer marker={renderMarker} cluster={renderCluster} method={gridSizedMethod} features={placeMarks} />
        <YMapListener onUpdate={updateHandler} />
      </YMap>
    </div>
  );
};

export default SchemaMap;
