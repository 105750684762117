import React, { FC, useCallback } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { deleteRequest } from '../../../../api';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import { IEmergencyReasonDeleteModalProps } from './types';
import { deleteEmergencyReasonUrl } from '../../../../constants/api';

const EmergencyReasonDeleteModal: FC<IEmergencyReasonDeleteModalProps> = (props) => {
  const { isOpen = false, onCancel = () => {}, item = null, onDeleted = () => {} } = props;

  const { sendRequest: deleteEmergencyReason, loading: isDeleting } = useApi<[]>(deleteRequest);

  const handleDelete = useCallback(async () => {
    if (!item) return;
    const err = await deleteEmergencyReason(deleteEmergencyReasonUrl(item.id));
    console.log('handleDelete', err);
    onDeleted(item);
  }, [deleteEmergencyReason, item, onDeleted]);

  return (
    <Modal
      isOpen={isOpen}
      title={`Вы действительно хотите удалить причину ЧС "${item?.name}"?`}
      centered
      width={416}
      onCancel={onCancel}
      showCloseIcon
      wrapClassName="delete-emergency-reason-modal__wrapper"
      footer={
        <div className="delete-emergency-reason-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel} disabled={isDeleting}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={handleDelete} disabled={isDeleting}>
            Удалить
          </Button>
        </div>
      }
    />
  );
};

export default EmergencyReasonDeleteModal;
