import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { IInformationPanelStatus } from '../types';
import PieChart from '../../../ui/pieChart';
import ChevronDown from '../../../../assets/svg/icons/chevronDown';
import PlanIcon from '../../../../assets/svg/icons/plan';
import EmergencyIcon from '../../../../assets/svg/icons/emergency';
import { paths } from '../../../../constants/paths';
import { objectInfoDefaultStatuses } from '../config';
import { getDevicesNumber, getPercent, updateStatuses } from '../functions';
import { IInformationPanelItem } from './types';
import InformationPanelDropDownItem from './DropdownItem';
import { checkIsDispatcher } from '../../../../store/selectors/profile';
import { useAppSelector } from '../../../../hooks/hooks';
import EmergencyModal from '../../emergencyModal';
import { IEmergencyParams } from '../../../../typings/dispatcherPanel';
import EmergencyConfirmModal from '../../emergencyConfirmModal';
import { getRequest, postRequest } from '../../../../api';
import { useApi } from '../../../../hooks/useApi';
import { checkObjectInEmergencyUrl, clearObjectEmergencyUrl, setObjectEmergencyUrl } from '../../../../constants/api';
import Tooltip from '../../../ui/tooltip';
import EmergencyClearConfirmModal from '../../emergencyClearConfirmModal';

const InformationPanelItem: FC<IInformationPanelItem> = ({
  showPointsName = false,
  wrapClassName,
  item,
  dispatcherPanelApiParams,
  setDispatcherPanelApiParams = () => {},
  onModal = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isEmergencyModalOpen, setEmergencyModalOpen] = useState(false);
  const [isEmergencyConfirmModalOpen, setEmergencyConfirmModalOpen] = useState(false);
  const [isEmergencyClearConfirmModalOpen, setEmergencyClearConfirmModalOpen] = useState(false);
  const [emergencyParams, setEmergencyParams] = useState<IEmergencyParams>();

  const navigate = useNavigate();

  const [statuses, setStatuses] = useState<IInformationPanelStatus[]>([...objectInfoDefaultStatuses]);

  const [searchParams, setSearchParams] = useSearchParams();

  const isDispatcher = useAppSelector(checkIsDispatcher);

  const { sendRequest: setObjectEmergency, loading: loadingSetObjectEmergency } = useApi(postRequest);
  const { sendRequest: clearObjectEmergency, loading: loadingClearObjectEmergency } = useApi(postRequest);
  const { sendRequest: checkObjectInEmergency } = useApi(getRequest);

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onEmergencyClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (loadingSetObjectEmergency || loadingClearObjectEmergency) return;
    if (!item?.isInEmergency) {
      setEmergencyModalOpen(true);
      onModal(true);
    } else {
      setEmergencyClearConfirmModalOpen(true);
      onModal(true);
    }
  };

  const onEmergencyModalOk = (data: IEmergencyParams) => {
    setEmergencyParams(data);
    setEmergencyModalOpen(false);
    setEmergencyConfirmModalOpen(true);
  };

  const onEmergencyModalCancel = () => {
    setEmergencyModalOpen(false);
    onModal(false);
  };

  const onEmergencyConfirmModalOk = () => {
    setEmergencyConfirmModalOpen(false);
    onModal(false);
    setObjectEmergency(setObjectEmergencyUrl(item?.objectId || ''), emergencyParams).then(() => {
      if (item) {
        checkObjectInEmergency(checkObjectInEmergencyUrl(item.objectId)).then((res) => {
          if (res.success) {
            item.isInEmergency = res.data;
          }
        });
      }
    });
  };

  const onEmergencyConfirmModalCancel = () => {
    setEmergencyConfirmModalOpen(false);
    onModal(false);
  };

  const onEmergencyClearConfirmModalOk = () => {
    setEmergencyClearConfirmModalOpen(false);
    onModal(false);
    if (!item) return;
    clearObjectEmergency(clearObjectEmergencyUrl(item.objectId), {}).then(() => {
      checkObjectInEmergency(checkObjectInEmergencyUrl(item.objectId)).then((res) => {
        if (res.success) {
          item.isInEmergency = res.data;
        }
      });
    });
  };

  const onEmergencyClearConfirmModalCancel = () => {
    setEmergencyClearConfirmModalOpen(false);
    onModal(false);
  };

  const filterByStatus = useCallback(
    (status: IInformationPanelStatus) => {
      const prevStatus = dispatcherPanelApiParams?.statuses && dispatcherPanelApiParams.statuses[0];
      if (
        dispatcherPanelApiParams &&
        dispatcherPanelApiParams.objectId === item?.objectId &&
        prevStatus === status.id
      ) {
        setDispatcherPanelApiParams({ ...dispatcherPanelApiParams, objectId: '', statuses: [] });
        searchParams.delete('statuses');
        searchParams.delete('objectId');
      } else {
        setDispatcherPanelApiParams({ ...dispatcherPanelApiParams, objectId: item?.objectId, statuses: [status.id] });
        searchParams.set('statuses', status.id);
        searchParams.set('objectId', item?.objectId || '');
      }
      setSearchParams(searchParams);
    },
    [dispatcherPanelApiParams, item?.objectId, searchParams, setDispatcherPanelApiParams, setSearchParams]
  );

  useEffect(() => {
    if (item) {
      setStatuses(updateStatuses(item, dispatcherPanelApiParams || {}));
    }
  }, [dispatcherPanelApiParams, item]);

  const devicesNumber = useMemo(() => getDevicesNumber(statuses), [statuses]);

  return (
    <>
      <div key={`${item?.objectId}-${item?.isInEmergency}`} className={`dispatcher-info-item ${wrapClassName}`}>
        <div className="dispatcher-info-item__title" role="presentation" onClick={onClick}>
          <div className="dispatcher-info-item__loader">
            <Spin size="large" spinning={loadingSetObjectEmergency || loadingClearObjectEmergency} />
          </div>
          <div
            className={classNames('dispatcher-info-item__title-arrow', {
              'dispatcher-info-item__title-arrow_open': isOpen,
            })}
          >
            <ChevronDown />
          </div>
          <div className="dispatcher-info-item__title-chart">
            <PieChart
              label={devicesNumber}
              segments={statuses.map((element) => ({
                color: element.colorCode,
                value: getPercent(element?.value, devicesNumber),
              }))}
            />
          </div>
          <div
            className={
              item?.isInEmergency ? 'dispatcher-info-item__title-label emergency' : 'dispatcher-info-item__title-label'
            }
          >
            {item?.objectName}
            {item?.isInEmergency && <span>Режим ЧС</span>}
          </div>
          <div className="dispatcher-info-item__title-plan">
            <Tooltip title="План объекта" placement="bottom">
              <span role="presentation" onClick={() => navigate(`${paths.dispatcherPanel}/${item?.objectId}/plan`)}>
                <PlanIcon />
              </span>
            </Tooltip>
            {isDispatcher && (
              <Tooltip
                title={!item?.isInEmergency ? 'Перевести объект в режим ЧС' : 'Отменить режим ЧС на объекте'}
                placement="bottom"
              >
                <span className="dispatcher-info-item__emergency" role="presentation" onClick={onEmergencyClick}>
                  <EmergencyIcon />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
        {isOpen && (
          <div className="dispatcher-info-item__list">
            {showPointsName
              ? statuses.map((status, index) => (
                  <InformationPanelDropDownItem key={`status-${status.id}-${index}`} data={status} />
                ))
              : statuses.map((status, index) => (
                  <div
                    key={`status-${status.id}-${index}`}
                    className={`dispatcher-info-item__list-item dispatcher-panel__status dispatcher-panel__status_${status.colorName}`}
                    role="presentation"
                    onClick={() => filterByStatus(status)}
                  >
                    {status.value} {status.label}
                  </div>
                ))}
          </div>
        )}
      </div>
      <EmergencyModal isOpen={isEmergencyModalOpen} onOk={onEmergencyModalOk} onCancel={onEmergencyModalCancel} />
      <EmergencyConfirmModal
        isOpen={isEmergencyConfirmModalOpen}
        onOk={onEmergencyConfirmModalOk}
        onCancel={onEmergencyConfirmModalCancel}
      />
      <EmergencyClearConfirmModal
        isOpen={isEmergencyClearConfirmModalOpen}
        onOk={onEmergencyClearConfirmModalOk}
        onCancel={onEmergencyClearConfirmModalCancel}
      />
    </>
  );
};

export default InformationPanelItem;
